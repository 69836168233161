const menu = [
  {
    icon: "dashlite",
    text: "Dashboard",
    link: "/",
  },
  /*{
    icon: "bitcoin-cash",
    text: "Crypto Dashboard",
    link: "/crypto",
  },
  {
    icon: "growth",
    text: "Analytics Dashboard",
    link: "/analytics",
  },
  {
    icon: "coins",
    text: "Invest Dashboard",
    link: "/invest",
  }, */
  {
    heading: "Master",
  },
  {
    icon: "tile-thumb",
    text: "Master",
    active: false,
    subMenu: [
      {
        text: "Manage Roles",
        link: "/manage-role",
      },
      /*{
        text: "Manage Permission",
        link: "/manage-permision",
      },
	 {
        text: "Map Role and Permision",
        link: "/map-role-permision",
      },*/
      {
        text: "Manage Users",
        link: "/manage-user",
      },
      /*{
        text: "Login with Admin",
        link: "/login-admin",
      },*/
      {
        text: "System Settings",
        link: "/system-setting",
      },
      {
        text: "Manage Subscription Plans",
        link: "/manage-subscription-plan",
      },
      {
        text: "Manage Funding States",
        link: "/manage-state",
      },
      {
        text: "Notification Settings",
        link: "/notification-setting",
      },
      // {
      //   text: "Tax Management",
      //   link: "/tax-management",
      // },
      {
        text: "Manage Learning Pillars",
        link: "/manage-learning-piller",
      },
      {
        text: "Manage Resource Type",
        link: "/manage-resource-type",
      },
      {
        text: "Manage Grade Levels",
        link: "/manage-grade-level",
      },
      /* {
        text: "Manage Topic Category",
        link: "/manage-topic-category",
      },
	  {
        text: "Manage Topic Subject",
        link: "/manage-topic-subject",
      },
	  {
        text: "Manage Topic",
        link: "/manage-topic",
      }, */
      {
        text: "Manage Learning Style",
        link: "/manage-learning-style",
      },
      {
        text: "Manage Educational Background",
        link: "/Manage-education-course",
      },
      {
        text: "Manage Special Learning Considerations",
        link: "/manage-special-learning-course",
      },
      {
        text: "Manage Instructional Styles",
        link: "/manage-instruction-style",
      },
      {
        text: "Types of Facilities",
        link: "/facility-type",
      },
      {
        text: "Types of Funding",
        link: "/funding-type",
      },
      {
        text: "Types of Support",
        link: "/support-type",
      },
      {
        text: "Types of Experiences",
        link: "/experience-type",
      },
      {
        text: "Types of Organizations",
        link: "/organization-type",
      },
	  {
        text: "Types of Content",
        link: "/content-type",
      },
      {
        text: "Sidebar Image",
        link: "/image",
      },
      {
        text: "Video Upload",
        link: "/video-upload",
      },
    ],
  },

  {
    icon: "cart",
    text: "System Default Notification",
    link: "/systemdefault-setting",
  },
  {
    icon: "user",
    text: "Customer",
    link: "/customer-list",
  },
 
  {
    icon: "list",
    text: "Resources",
    link: "/resource-list",
  },
  {
    icon: "report",
    text: "Offense Report",
    link: "/offensive-list",
  },
  {
    icon: "users",
    text: "Scheduled Sessions",
    link: "/mentor-list",
  },
  {
    icon: "cart",
    text: "Subscription Purchases",
    link: "/subscription-list",
  },
  {
    icon: "history",
    text: "Resource Purchases",
    link: "/purchase-list",
  },
  {
    icon: "file-docs",
    text: "Payment Report",
    link: "/payment-report",
  },
  {
    icon: "search",
    text: "Search Analytics",
    link: "/search-analytics",
  },
  {
    icon: "users",
    text: "Group",
    link: "/group-list",
  },
  // {
  //  heading:"Reports"
  // },
  {
    icon: "reports",
    text: "Reports",
    active: false,
    subMenu: [
      {
        text: "Customer Report",
        link: "/customer-report",
        
      },
     
      {
        text: "Login Report",
        link: "/customer-login-report",
      },
      {
        text: "Search Report",
        link: "/customer-search-report",
      },
      {
        text: "Search Result Report",
        link: "/customer-search-result-report",
      },
      {
        text: "Saved Report",
        link: "/customer-stored-report",
      },
      {
        text:"Space Used Report",
        link: "/customer-space-report",
      },
     
      {
        text: "Friend Report",
        link: "/friends-report",
      },
      // {
      //   text: "Friend List reports",
      //   link: "/friends-list-report",
      // },
      {
        
        text: "Customer Purchased Report",
        link: "/customer-purchase-report",
      },
      {
        text: "Share Report",
        link: "/customer-share-report",
      },
      {
        text: "Resource Report",
        link: "/customer-resource-report",
      },
      {
        text: "Subscription Payment Report",
        link: "/subscription-payment-report",
      },
      {
        text: "Scheduled Sessions Payment Report",
        link: "/mentoring-session-payment-report",
      },
      {
        text: "Resource Purchase Payment Report",
        link: "/purchase-resource-payment-report",

      },
      {
        text: "Resource Get More Info Report",
        link: "/resource-getmoreinfo-report",

      },
      {
        text: "Resource Enroll Report",
        link: "/resource-enrollment-report",

      },
      {
        text: "Resource Signup Report",
        link: "/resource-signup-report",

      },
      {
        text: "Group Click Report",
        link: "/group-click-report",

      },

      {
        text: "Group Resource Clicked Report",
        link: "/group-resource-click-report",

      },
      {
        text: "Resource Clicked Report by Individual",
        link: "/individual-click-report",

      },

    ]
    },

];
export default menu;

import React, { useState, useMemo } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { capital } from "../../../utils/Utils";
import {
  Icon,
} from "../../../components/Component";
import { Card } from "reactstrap";
import { Label, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makePostRequest } from "../../../service/api";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";

function TypeofFunding() {
  const [getAllFunding, setGetAllFunding] = useState([]);
  const [selectedFunding, setSelectedFunding] = useState({});
  const [name, setName] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [expressionTextError, setErrorExpressionText] = useState("");
  const [errorname, setErrorName] = useState("");
  const [switchState, setSwitchState] = useState("");
  const [loader, setLoader] = useState(false);
  let payloadarray = [];
  let tempData = [];
  const [payloadarraypass, setPayloadarraypass] = useState([]);
  const handleswitch = (id, isSelected, index) => {
    setLoader(true);
    let data = {
      id: id,
      status: isSelected,
    };
    switchState ? setSwitchState("enable") : setSwitchState("disable");
    const newArray = Array.from(getAllFunding);
    newArray[index].status = isSelected === "enable" ? "disable" : "enable";
    setGetAllFunding(newArray);
    makePostRequest(URLS.UPDATE_FUNDING_TYPE, null, data, null).then((res) => {
      if (res.code === 200) {
        toast.success("Status updated successfully.");
        setLoader(false);
        handlViewFunding();
        setIsUpdate(false);
        setGetAllFunding({});
      } else {
        toast.warn("Status disable successfully.");
      }
    });
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    tempData = Array.from(getAllFunding);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setGetAllFunding(tempData);
    tempData.length > 0 &&
      tempData.map((val, index) => {
        payloadarray.push({ sort: tempData.length - index, id: val.id });
      });
    setPayloadarraypass(payloadarray);
  };
  const handleNames = (e) => {
    setName(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
    setErrorName("");
    setErrorExpressionText("");
    let obj = { ...selectedFunding };
    let str = e.target.value;
    obj.name = str;
    setSelectedFunding({ ...obj });
  };
  let char = /^[a-zA-Z\s]+$/;
  let num = /^[0-9]+$/;

  const AddFundingHandler = () => {
    if (name == "") {
      setErrorName("Please enter type of funding.");
    }
    else if (!name.match(char)) {
      setErrorExpressionText("Please enter character only.");
    }
    else {
      addNewFunding();
      setLoader(true);
    }
  };
  const HandleButtonUpdateFunding = () => {
    if (selectedFunding.name.replace(/^\s+|\s+$|\s+(?=\s)/g, "") == "") {
      setErrorName("Please enter type of funding.");
    }
    else if (!selectedFunding.name.match(char)) {
      setErrorExpressionText("Please enter character only.");
    }
    else if (isUpdate) {
      handleupdateFund();
      setLoader(true);
    }
  };
  const SortFacility = () => {
    let data = payloadarraypass ? payloadarraypass : [];
    makePostRequest(URLS.SORTFUNDING, null, data, null).then((res) => {
      if (res.code == 200) {
        toast.success(res.message);
        handlViewFunding();
      } else if (res.code == 400) {
        setLoader(false);
        toast.error(res.message);
      }
    });
  };

  useEffect(() => {
    if (payloadarraypass.length > 0) {
      SortFacility();
    }
  }, [payloadarraypass]);

  const addNewFunding = () => {
    let data = {
      name: capital(name),
      status: "enable",
    };
    makePostRequest(URLS.ADD_FUNDING_TYPE, null, data, null).then((res) => {
      if (res.code == 201) {
        setName("");
        setSelectedFunding({ ...selectedFunding, name: "" });
        toast.success(res.message);
        setLoader(false);
        setIsUpdate(false);
        handlViewFunding();
        setSelectedFunding({});
      } else if (res.code == 400) {
        setLoader(false);
        toast.error(res.message);
      }
    });
  };
  useEffect(() => {
    handlViewFunding();
  }, []);

  const handlViewFunding = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_FUNDING_TYPE, {}, null)
      .then((res) => {
        if (res.code === 200) {
          setGetAllFunding(res.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFundEditButton = (val) => {
    setIsUpdate(true);
    let obj = { id: val.id, name: val.name };
    setSelectedFunding(obj);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    if (errorname) {
      setErrorName("");
    }
  };

  const handleupdateFund = () => {
    let data = {
      id: selectedFunding.id,
      name: capital(selectedFunding.name.replace(/^\s+|\s+$|\s+(?=\s)/g, "")),
    };
    makePostRequest(URLS.UPDATE_FUNDING_TYPE, null, data, null).then((res) => {
      if (res.code == 200) {
        setName("");
        setSelectedFunding({ ...selectedFunding, name: "" });
        toast.success(res.message);
        setLoader(false);
        handlViewFunding();
        setIsUpdate(false);
      } else if (res.code == 400) {
        setLoader(false);
        toast.error(res.message);
      }
    });
  };
  return (
    <React.Fragment>
      <Head title="Funding Setting"></Head>
      <Content>
        <ToastContainer />
        <h3 className="nk-block-title page-title mb5 pb-4">Type of Funding</h3>
        {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "250px" }}>
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : (
          <Card className="card-bordered">
            <div className="card-inner">
              <BlockHeadContent>
                <BlockHead size="lg">
                  <div className="row mb-4">
                    <div className="col-lg-4">
                      {isUpdate === false ? (
                        <Label className="form-label">Add Type of Funding</Label>
                      ) : (
                        <Label className="form-label">Edit Type of Funding</Label>
                      )}
                      <div className="form-control-wrap">
                        <Input
                          name="name"
                          placeholder="Type of Funding Name"
                          type="text"
                          value={selectedFunding.name}
                          onChange={handleNames}
                        />
                        <span className="error"> {errorname} </span>
                        <span className="error"> {expressionTextError} </span>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <Label className="form-label">&nbsp;</Label>
                      {isUpdate === false ? (
                        <ul className="">
                          <li>
                            <button className="btn btn-primary btn-md" type="submit" onClick={AddFundingHandler}>
                              Add{" "}
                            </button>
                          </li>
                        </ul>
                      ) : (
                        <ul className="">
                          <li>
                            <button
                              className="btn btn-primary btn-md"
                              type="submit"
                              onClick={HandleButtonUpdateFunding}
                            >
                              Update{" "}
                            </button>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>

                  {isUpdate === false && (
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <table className="table borderd">
                        <thead>
                          <tr className="p-3 bg-white border border-light round-lg mb-3">
                            <th />
                            <th>#</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <Droppable droppableId="droppable-1">
                          {(provider) => (
                            <tbody className="text-capitalize" ref={provider.innerRef} {...provider.droppableProps}>
                              {getAllFunding?.map((user, index) => (
                                <Draggable
                                  key={user.name}
                                  draggableId={user.name}
                                  index={index}
                                >
                                  {(provider) => (
                                    <tr
                                      {...provider.draggableProps}
                                      ref={provider.innerRef}
                                      className="p-3 bg-white border border-light round-lg mb-3"
                                    >
                                      <td {...provider.dragHandleProps}>
                                        <div className=" mt-2 mb-2">
                                          <FontAwesomeIcon icon={faGripVertical} />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        <div className=" mt-2 mb-2">{index + 1}</div>
                                      </td>
                                      <td>
                                        <div className="user-card mt-2 mb-2">
                                          <div className="user-info">
                                            <span className="userName">{user.name} </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <>
                                          {user.status == "enable" && (
                                            <div className="custom-control custom-switch">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={user.id}
                                                onClick={() => handleswitch(user.id, "disable", index)}
                                                checked
                                              />
                                              <label className="custom-control-label" htmlFor={user.id}></label>
                                            </div>
                                          )}
                                          {user.status == "disable" && (
                                            <div className="custom-control custom-switch">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={user.id}
                                                onClick={() => handleswitch(user.id, "enable", index)}
                                              />
                                              <label className="custom-control-label" htmlFor={user.id}></label>
                                            </div>
                                          )}
                                        </>
                                      </td>
                                      <td>
                                        <div className="user-card mt-2 mb-2">
                                          <button
                                            className="dropdown-item"
                                            onClick={() => {
                                              handleFundEditButton(user);
                                            }}
                                          >
                                            <Icon name="edit"></Icon>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                              {provider.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                    </DragDropContext>
                  )}
                </BlockHead>
              </BlockHeadContent>
            </div>
          </Card>
        )}
      </Content>
    </React.Fragment>
  );
}

export default TypeofFunding;

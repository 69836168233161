import React, { useMemo, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BASE_URL, getHeaders, makePostRequest } from "../../../service/api";
import { S3_BUCKET_URL } from "../../../constants";
import bnb from "../../../images/coins/bnb.svg";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { Label, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { capital } from "../../../utils/Utils";

function ManageSubscriptionPlan() {
  const [selectedSubscriptionplan, setselectedSubscriptionplan] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [errorname, setErrorName] = useState("");
  const [rolesData, setRolesData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState("");
  const [instruction, setInstruction] = useState("");
  const [price, setPrice] = useState("");
  const [spaceAllocated, setSpaceAllocated] = useState("");
  const [description, setDescription] = useState("");
  const [paymentFrequencyType, setPaymentFrequencyType] = useState("");
  const [duration, setDuration] = useState("");
  const [membershipType, setMembershipType] = useState("");
  const [stripeProductId, setStripeProductId] = useState("");
  const [status, setStatus] = useState("");
  const [instructionError, setInstructionError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [spaceAllocatedError, setSpaceAllocatedError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [paymentFrequencyTypeError, setPaymentFrequencyTypeError] = useState("");
  const [durationError, setDurationError] = useState("");
  const [membershipTypeError, setMembershipTypeError] = useState("");
  const [expressionTextError, setErrorExpressionText] = useState("");
  // const [addinstruction,setAddinstruction]=useState([]);
  const [selectesHeading, setSelectedHeading] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedSubTitle, setSelectedSubTitle] = useState("");
  const [tableShow, setTableShow] = useState(false);
  const [instructionData, setInstructionData] = useState([]);
  const [dynamicError, setDynamicError] = useState({});
  const [allHeading, setAllHeading] = useState([]);
  const [allMasterList, setAllMasterList] = useState([]);
  const [checklistData, setChecklistData] = useState([]);
  const [memberTypevalue, setmemberTypevalue] = useState([]);
  const [checkStatus, setCheckStatus] = useState("");
  const [statusfromgetapi, setStatusfromgetapi] = useState([]);
  const [allSubHeading, setAllSubHeading] = useState([]);
  const [selectedSubheading, setSelectedSubheading] = useState("");

  let heading = ["Account Users", "Friends and Following", "Searches", "Saves", "Serve", "Share"];

  const heandleError = () => {
    let errors = {};
    if (selectedTitle == "") {
      errors["selectedTitle"] = "Please  add title.";
    }
    if (selectesHeading == "" && membershipType == "community_membership") {
      errors["selectesHeading"] = "Please select heading.";
    }
    if (selectedSubTitle == "") {
      errors["selectedSubTitle"] = "Please add sub tilte.";
    }
    setDynamicError(errors);
    if (Object.keys(errors).length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const [checkedData, setCheckedData] = useState([]);

  const [switchState, setSwitchState] = useState("");
  const columns = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "60px",
        style: { minWidth: "60px" },
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2"> {index + 1} </div>
          </>
        ),
      },
      {
        name: "Title",
        selector: (row) => (""),
		
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">
                {row.title == null ? <div>--</div> : row.title}
                {""}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Price($)",
        selector: (row) => row.price,
        grow: 1,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-sub">
                ${row.price ? row.price :"0"}
              </span>
            </div>
          </div>
        ),
      },
	  {
        name: "Space Allocated",
        selector: (row) =>"",
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-sub">
                {row.space_allocated} GB
              </span>
            </div>
          </div>
        ),
      },

      // {
      // 		name: 'Status',
      // 		selector: row => row.status,
      // 		cell: (row, index) => (
      // 			<>
      // 			{
      // 				row.status == "enable" &&
      // 				<div className="custom-control custom-switch">
      // 					<input type="checkbox" className="custom-control-input" id={row.id} onClick={() => handleswitch(row.id, "disable", index)} checked />
      // 					<label className="custom-control-label" htmlFor={row.id}>
      // 					</label>
      // 				</div>

      // 			}
      // 			{row.status == "disable" &&
      // 				<div className="custom-control custom-switch">
      // 					<input type="checkbox" className="custom-control-input" id={row.id} onClick={() => handleswitch(row.id, "enable", index)} />
      // 					<label className="custom-control-label" htmlFor={row.id}>
      // 					</label>
      // 				</div>
      // 			}

      // 		</>
      // 		)
      // 	},

      {
        name: "Payment Type",
        selector: (row) => row.payment_frequency_type,
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-sub">
                {row.payment_frequency_type ==="free" ? "Life Time" : capital(row.payment_frequency_type)}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Duration",
        selector: (row) => row.duration,
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-sub">{row.duration} Days</span>
            </div>
          </div>
        ),
      },
      {
        name: "Membership Type",
        selector: (row) => row.membership_type,
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-sub">{row.tbl_subscription_heading.title}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Edit",
        selector: (row) => row.status,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
           {row.payment_frequency_type ==="free" ? "":
		    <button
              className="dropdown-item"
              onClick={() => {
                handleEditButton(row);
              }}
            >
              <Icon name="edit"></Icon>
            </button>
	  }
          </div>
        ),
      },
    ];
  }, [rolesData]);

  // const handleswitch=(id, isSelected, index)=>{
  // 	setLoader(true);
  // 	const formData = new FormData();
  // 	formData.append("id",id);
  // 	formData.append("status", isSelected);

  // 	switchState?setSwitchState("enable"):setSwitchState("disable");
  // 	const newArray = Array.from(rolesData);
  // 	newArray[index].status = isSelected === "enable" ? "disable" : "enable";
  // 	setRolesData(newArray);

  // 	makePostRequest(URLS.UPDATE_SUBSCRIPTION_PLAN, null,formData, null)
  // 		.then(res => {
  // 				toast.success("Status updated successfully.");
  // 			     setLoader(false);
  // 				getAllSubscriptionPlan();
  // 		 	   setIsUpdate(false);
  // 				setselectedSubscriptionplan({});
  // 		})
  // }

  const HandleSelectedHeading = (e) => {
    setSelectedHeading(e.target.value);
    if (e.target.value !== "") {
      setDynamicError({ ...dynamicError, selectesHeading: "" });
    }
  };
  const handleInsTitle = (e) => {
    setSelectedTitle(e.target.value);
    if (e.target.value !== "") {
      setDynamicError({ ...dynamicError, selectedTitle: "" });
    }
  };
  const handleInsSubtitle = (e) => {
    setSelectedSubTitle(e.target.value);
    if (e.target.value !== "") {
      setDynamicError({ ...dynamicError, selectedSubTitle: "" });
    }
  };

  const handleTitle = (e) => {
    setTitle(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
    setErrorName("");
    setErrorExpressionText("");
    let obj = { ...selectedSubscriptionplan };
    let str = e.target.value;
    obj.title = str;
    setselectedSubscriptionplan({ ...obj });
  };
  const handleInstruction = (e) => {
    setInstruction(e.target.value);
    setInstructionError("");
    let obj = { ...selectedSubscriptionplan };
    let str = e.target.value;
    obj.instruction = str;
    setselectedSubscriptionplan({ ...obj });
  };
  const handlePrice = (e) => {
    setPrice(e.target.value);
    setPriceError("");
    let obj = { ...selectedSubscriptionplan };
    let int = e.target.value;
    obj.price = int;
    setselectedSubscriptionplan({ ...obj });
  };
  const handleSpaceAllocated = (e) => {
    setSpaceAllocated(e.target.value);
    setSpaceAllocatedError("");
    let obj = { ...selectedSubscriptionplan };
    let str = e.target.value;
    obj.spaceAllocated = str;
    setselectedSubscriptionplan({ ...obj });
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
    setDescriptionError("");
    let obj = { ...selectedSubscriptionplan };
    let str = e.target.value;
    obj.description = str;
    setselectedSubscriptionplan({ ...obj });
  };
  const handlePaymentFrequencyType = (e) => {
    setPaymentFrequencyType(e.target.value);
    setPaymentFrequencyTypeError("");
    let obj = { ...selectedSubscriptionplan };
    let str = e.target.value;
    obj.paymentFrequencyType = str;
    setselectedSubscriptionplan({ ...obj });
  };
  const handleDuration = (e) => {
    setDuration(e.target.value);
    setDurationError("");
    let obj = { ...selectedSubscriptionplan };
    let str = e.target.value;
    obj.duration = str;
    setselectedSubscriptionplan({ ...obj });
  };
  const handleMembershipType = (e) => {
    setMembershipType(e.target.value);
    setMembershipTypeError("");
    let obj = { ...selectedSubscriptionplan };
    let str = e.target.value;
    obj.membershipType = str;
    setselectedSubscriptionplan({ ...obj });
    // setAllSubHeading("")
    setSelectedSubheading("");
  };

  const HandleSelectedSubHeading = (e) => {
    setSelectedSubheading(e.target.value);
    //setMembershipType("")
  };

  const selectedCheckList = (e, val, index) => {
    if (e.target.checked) {
      setCheckedData([...checkedData, { checklist_id: val.id, status: "active" }]);
    } else {
      let data = checkedData?.map((v) =>
        v?.checklist_id == val?.id ? { checklist_id: val.id, status: "deactive" } : { ...v }
      );
      setCheckedData(data);
    }
  };

  let char = /^[a-zA-Z\s]+$/;

/*Add subscription Plan validations commented*/

//   const AddSubscriptionHandler = () => {
//     window.scrollTo({
//       top: 50,
//       behavior: "smooth",
//     });

//     if (membershipType == "") {
//       setMembershipTypeError("Please enter membership type.");
//     } else if (paymentFrequencyType == "") {
//       setPaymentFrequencyTypeError("Please enter paymet frequence type.");
//     } else if (title == "") {
//       setErrorName("Please enter your name.");
//     } else if (duration == "" && paymentFrequencyType != "free") {
//       setDurationError("Please enter duration.");
//     } else if (price == "" && paymentFrequencyType != "free") {
//       setPriceError("Please enter price.");
//     } else if (spaceAllocated == "" && paymentFrequencyType != "free") {
//       setSpaceAllocatedError("Please enter space allocated.");
//     }
//     //    else if (description == "") {
//     // 		setDescriptionError('Please enter description.');
//     // 	}
//     //    else if (addinstruction.length == 0) {
//     // 		setInstructionError('Please enter instruction.');
//     // 	}
//     else if (!title.match(char)) {
//       setErrorExpressionText("Please enter character only.");
//     } else {
//       addsubscription();
//       
//     }
//   };

   const UpdateSubscriptionHandler = () => {

	if (selectedSubscriptionplan.price == "") {
		       setPriceError("Please enter price.");
		     } 
			 
		 else if (isNaN(selectedSubscriptionplan.price)) {
				 setPriceError("Please enter number.")
				
			  } else if (selectedSubscriptionplan.price < 0) {
				 setPriceError("Negative number is not allowed.")
				
			  } else if (selectedSubscriptionplan.price == 0) {
				 setPriceError("price can't be 0.")
			  }
			  else{
				handleupdateUser()
			}

//     if (selectedSubscriptionplan.membershipType == "") {
//       setMembershipTypeError("Please enter membership type.");
//     } else if (selectedSubscriptionplan.paymentFrequencyType == "") {
//       setPaymentFrequencyTypeError("Please enter paymet frequence type.");
//     } else if (selectedSubscriptionplan.title == "") {
//       setErrorName("Please enter your name.");
//     } else if (selectedSubscriptionplan.duration == "" && selectedSubscriptionplan.paymentFrequencyType != "free") {
//       setDurationError("Please enter duration.");
//     } else if (selectedSubscriptionplan.price == "" && selectedSubscriptionplan.paymentFrequencyType != "free") {
//       setPriceError("Please enter price.");
//     } else if (
//       selectedSubscriptionplan.spaceAllocated == "" &&
//       selectedSubscriptionplan.paymentFrequencyType != "free"
//     ) {
//       setSpaceAllocatedError("Please enter space allocated.");
//     }
//     //   else if (selectedSubscriptionplan.description == "") {
//     // 	   setDescriptionError('Please enter description.');
//     //    }

//     //   else if (addinstruction.length == 0) {
//     // 	   setInstructionError('Please enter instruction.');
//     //    }
//     else if (!selectedSubscriptionplan.title.match(char)) {
//       setErrorExpressionText("Please enter character only.");
//     } else if (isUpdate) {
//       handleupdateUser();
//     }
 };

/*Add Subscription plan commented*/

//   function addsubscription() {
//     let data = {
//       title: title,
//       price: price,
//       space_allocated: spaceAllocated,
//       description: description || "",
//       payment_frequency_type: paymentFrequencyType,
//       duration: paymentFrequencyType === "free" ? "0" : duration,
//       subscription_heading_id: membershipType,
//       status: "enable",
//       checklist: checkedData,
//     };
//     //console.log("data",data)

//     makePostRequest(URLS.ADD_SUBSCRIPTION_PLAN, null, data, null).then((res) => {
//       if (res.code == 201) {
//         let Objects = {
//           title: "",
//           price: "",
//           spaceAllocated: "",
//           description: "",
//           duration: "",
//           membershipType: "",
//           addinstruction: "",
//           paymentFrequencyType: "",
//         };
//         setselectedSubscriptionplan(Objects);
//         setPaymentFrequencyType("");
//         setMembershipType("");
//         setSelectedSubheading("");
//         getAllSubscriptionPlan();
//         setIsUpdate(false);
//         setselectedSubscriptionplan({});
//         //   setAddinstruction([])
//         toast.success(res.message);

//         // console.log("fileupload responsesss = = \n", response)
//       } else if (res.code == 400) {
//         toast.error(res.message);
//       }
//     });
//   }

  useEffect(() => {
    getAllSubscriptionPlan();
    getAllheading();
  }, []);

  const getAllSubscriptionPlan = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_SUBSCRIPTION_PLAN, {}, null)
      .then((res) => {
        console.log(res,"responsesubscription")
        if (res.code == 200) {
          setRolesData(res.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAllheading = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_HEADING, {}, null)
      .then((res) => {
        if (res.code == 200) {
          setAllHeading(res.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  const getAllSubHeading = () => {
    setLoader(true);
    let data = {
      subscription_heading_id: membershipType,
    };

    makePostRequest(URLS.GET_ALL_SUBHEADING, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setAllSubHeading(res.data);
          //console.log(res.data,"sub")
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
/*Subscription plan sub heading  Commented*/

//   const getAllmasterlist = () => {
//     setLoader(true);
//     let data;
//     if (membershipType) {
//       data = {
//         subscription_heading_id: membershipType ? membershipType : "",
//         subscription_plan_subheading_id: selectedSubheading ? selectedSubheading : "",
//       };
//     }
//     if (selectedSubheading) {
//       data = {
//         subscription_plan_subheading_id: selectedSubheading,
//         subscription_heading_id: membershipType ? membershipType : "",
//       };
//     }

//     makePostRequest(URLS.GET_ALL_MASTERLIST, null, data, null)
//       .then((res) => {
//         setLoader(false);
//         //console.log(res,"reslist")
//         if (res.code == 200) {
//           setAllMasterList(res.data);
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

  useEffect(() => {
    // getAllmasterlist();
  }, [membershipType]);

  useEffect(() => {
    getAllSubHeading();
    // getAllmasterlist();
  }, [selectedSubheading]);

  const handleEditButton = (val) => {
    // getAllmasterlist();
    setErrorName("");
    setPriceError("");
    setDescriptionError("");
    setSpaceAllocatedError("");
    setInstructionError("");
    setDurationError("");
    setPaymentFrequencyTypeError("");
    setMembershipTypeError("");
    setIsUpdate(true);
    let obj = {
      id: val.id,
      title: val.title,
      instruction: val.instruction,
      price: val.price,
      spaceAllocated: val.space_allocated,
      description: val.description,
      paymentFrequencyType: val.payment_frequency_type,
      duration: val.duration,
      membershipType: val.tbl_subscription_heading.id,
      stripeProductId: val.stripe_product_id,
    };
    setselectedSubscriptionplan(obj);
    setMembershipType(val.tbl_subscription_heading.id);
    setmemberTypevalue(val.tbl_subscription_heading.title);
    setStatusfromgetapi(val.tbl_subscription_checklist_mappings);

    setStatusfromgetapi(val.tbl_subscription_checklist_mappings);
    let checkArray = [];
    let checkliststatus = val?.tbl_subscription_checklist_mappings;
    checkliststatus.length > 0 &&
      checkliststatus.forEach((val, index) => {
        checkArray.push({ checklist_id: val.checklist_id, status: val.status });
      });
    setCheckedData(checkArray);
    // const convertToArray = (ddd) => {
    // 	if(Array.isArray(ddd)) {
    // 		return setAddinstruction(ddd)
    // 	}else{
    // 		let neww = JSON.parse(ddd)
    // 		convertToArray(neww)
    // 	}
    // }
    // convertToArray(val.instruction)
    setTableShow(true);
  };

  function handleupdateUser() {
    let data = {
      id: selectedSubscriptionplan.id,
    //   title: selectedSubscriptionplan.title,
      price: selectedSubscriptionplan.price,
    //   space_allocated: selectedSubscriptionplan.spaceAllocated,
    //   description: selectedSubscriptionplan.description,
    //   payment_frequency_type: selectedSubscriptionplan.paymentFrequencyType,
    //   duration: selectedSubscriptionplan.duration,
    //   subscription_heading_id: selectedSubscriptionplan.membershipType,
    //   checklist: checkedData,
    };

    //console.log("addinstruction data", data)
    makePostRequest(URLS.UPDATE_SUBSCRIPTION_PLAN, null, data, null).then((res) => {
      if (res.code == 200) {
        let Objects = {
          title: "",
          price: "",
          spaceAllocated: "",
          description: "",
          paymentFrequencyType: "",
          duration: "",
          membershipType: "",
        };
        setselectedSubscriptionplan(Objects);
        toast.success(res.message);
        setIsUpdate(false);
        setselectedSubscriptionplan({});
        setPaymentFrequencyType("");
        setMembershipType("");
        setSelectedSubheading("");
        //   setAddinstruction([])
        getAllSubscriptionPlan();
        let obj = { name: "" };
        setselectedSubscriptionplan({ ...obj });
      } else if (res.code == 400) {
        toast.success(res.message);
      }
    });
  }

  return (
    <React.Fragment>
      <Head title="Subscription Plan"></Head>
      <Content>
        <ToastContainer />
        <h3 className="nk-block-title page-title mb5 pb-4">
          {" "}
          Manage Subscription Plans

{/*Manage CheckList button commented */}

          {/* <Link to="/manage-checklist">
            <button className=" d-sm-inline-flex float-end btn btn-primary btn-md">
              <span>Manage Check List</span>
            </button>
          </Link> */}

        </h3>
        {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "250px" }}>
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : (
          <Card className="card-bordered">
            <div className="card-inner">
              <BlockHeadContent>
                <BlockHead size="lg">
                   {/* <div className="row"> */}
                    {/* <div className="col-lg-4">
                      <div className="form-group mb-2">
                        <Label className="form-label">Membership Type</Label>
                        <select
                          value={selectedSubscriptionplan.membershipType}
                          className="form-select"
                          onChange={handleMembershipType}
                        >
                          <option value=""> Membership Type</option>

                          {allHeading.map((val) => {
                            return (
                              <>
                                <option value={val.id}>{val.title}</option>
                              </>
                            );
                          })}
                        </select>
                        <span className="error"> {membershipTypeError} </span>
                      </div>
                    </div> */}
                    {/* <div className="col-lg-4">
                      <div className="form-group mb-2">
                        <Label className="form-label">Payment Frequency Type</Label>
                        <select
                          value={selectedSubscriptionplan.paymentFrequencyType}
                          className="form-select"
                          onChange={handlePaymentFrequencyType}
                        >
                          <option value="">Payment Type</option>

                          <option value="free">Free</option>
                          <option value="monthly">Monthly</option>
                          <option value="yearly">Yearly</option>
                        </select>
                        <span className="error"> {paymentFrequencyTypeError} </span>
                      </div>
                    </div> */}

                    {/*<div className="col-lg-4">
                      <div className="form-group mb-2">
                        <Label className="form-label">Title</Label>
                        <div className="form-control-wrap">
                          <Input
                            name="name"
                            placeholder="Title"
                            type="text"
                            value={selectedSubscriptionplan.title}
                            onChange={handleTitle}
                          />
                          <span className="error"> {errorname} </span>
                          <span className="error"> {expressionTextError} </span>
                        </div>
                      </div>
                    </div>
                  </div> */}


                  {/* {paymentFrequencyType === "free" ? null : (
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group mb-2">
                          <Label className="form-label">Duration (Days)</Label>
                          <div className="form-control-wrap">
                            <Input
                              name="name"
                              placeholder="Duration in Days"
                              type="text"
                              value={selectedSubscriptionplan.duration}
                              onChange={handleDuration}
                            />
                            <span className="error"> {durationError} </span>
                          </div>
                        </div>
                      </div>*/}
					   {isUpdate === true ?
                      <div className="col-lg-4">
                        <div className="form-group mb-2">
                          <Label className="form-label">Price($)</Label>
                          <div className="form-control-wrap">
                            <Input
                              name="name"
                              placeholder="Price($)"
                              type="text"
                              value={selectedSubscriptionplan.price}
                              onChange={handlePrice}
                            />
                            <span className="error"> {priceError} </span>
                          </div>
                        </div>
                      </div>
					  :""}

{/*
                      <div className="col-lg-4">
                        <div className="form-group mb-2">
                          <Label className="form-label">Space Allocated (GB)</Label>
                          <div className="form-control-wrap">
                            <Input
                              name="name"
                              placeholder="Space Allocated"
                              type="text"
                              value={selectedSubscriptionplan.spaceAllocated}
                              onChange={handleSpaceAllocated}
                            />
                            <span className="error"> {spaceAllocatedError} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}


                  {/* {membershipType == 1 ? (
                    <div className="col-lg-3 mb-2">
                      <select className="form-select" onChange={HandleSelectedSubHeading}>
                        <option value={selectedSubscriptionplan.selectedSubheading}> Select subtitle</option>
                        {allSubHeading.length > 0 &&
                          allSubHeading.map((val) => {
                            return (
                              <>
                                <option value={val.id}>{val.title}</option>
                              </>
                            );
                          })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )} */}

                  {/* {membershipType == 2 || membershipType == 3 || selectedSubheading ? (
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">List</th>
                          <th scope="col">view</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allMasterList.map((val, index) => {
                          return (
                            <>
                              <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{val.heading}</td>
                                <td>
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id={`flexCheckDefault-${val.id}`}
                                      checked={
                                        checkedData.some((el) => el.checklist_id == val.id && el.status == "active") ==
                                        true
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        selectedCheckList(e, val, index);
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    ""
                  )} */}



<div className="row">
                    <div className="col-lg-3 mb-2">
                      {isUpdate === true ? 
                      <>
                        <ul className="align-center flex-sm-nowrap mt-4 pt-1  float-start">
                          <li>
                            <button
                              className="btn btn-primary btn-md"
                              type="submit"
                              onClick={UpdateSubscriptionHandler}
                            >
                              Update{" "}
                            </button>
                          </li>
                        </ul>
						</>
						:""
                      }
                    </div>
                  </div>
					
                   {/* <div className="row">
                    <div className="col-lg-3 mb-2">
                      {isUpdate === false ? ( 
                        <ul className="align-center flex-sm-nowrap mt-4 pt-1  float-start">
                          <li>
                            <button className="btn btn-primary btn-md" type="submit" onClick={AddSubscriptionHandler}>
                              Add{" "}
                            </button>
                          </li>
                        </ul>
                      ) : (
                        <ul className="align-center flex-sm-nowrap mt-4 pt-1  float-start">
                          <li>
                            <button
                              className="btn btn-primary btn-md"
                              type="submit"
                              onClick={UpdateSubscriptionHandler}
                            >
                              Update{" "}
                            </button>
                          </li>
                        </ul>
                      )}{" "}
                    </div>
                  </div>  */}

                  {isUpdate === false && (
                    <div className="">
                      {rolesData && rolesData.length == 0 ? (
                        <div>Loading...</div>
                      ) : (
                        <ReactDataTable
                          data={rolesData}
                          columns={columns}
                          className="nk-tb-list subscriptionplanCustomeInner"
                        //   searchable={true}
                          //pagination
                        />
                      )}
                    </div>
                  )}
                </BlockHead>
              </BlockHeadContent>
            </div>
          </Card>
        )}
      </Content>
    </React.Fragment>
  );
}

export default ManageSubscriptionPlan;

import React, { useState, useMemo } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import {
  Icon,
} from "../../../components/Component";
import { Label, Input, Card } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makePostRequest } from "../../../service/api";
import { findUpper } from "../../../utils/Utils";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";

function ManageInstructionStyle() {
  const [selectedinstructionstyle, setSelectedInstructionStyle] = useState({});
  const [name, setName] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [errorname, setErrorName] = useState("");
  const [rolesData, setRolesData] = useState([]);
  const [expressionTextError, setErrorExpressionText] = useState("");
  let payloadarray = [];
  let tempData = [];

  const [payloadarraypass, setPayloadarraypass] = useState([]);
  const [loader, setLoader] = useState(false);
  const [switchState, setSwitchState] = useState("");
  const handleswitch = (id, isSelected, index) => {
    setLoader(true);
    let data = {
      id: id,
      status: isSelected,
    };
    switchState ? setSwitchState("enable") : setSwitchState("disable");
    const newArray = Array.from(rolesData);
    newArray[index].status = isSelected === "enable" ? "disable" : "enable";
    setRolesData(newArray);
    makePostRequest(URLS.UPDATE_INSTRUCTION_STYLE, null, data, null).then((res) => {
      if (res.code === 200) {
        toast.success("Status updated successfully.");
        setLoader(false);
        GetAllInstructionalStyle();
        setIsUpdate(false);
      } else {
        toast.warn("Status disable successfully.");
      }
    });
  };
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    tempData = Array.from(rolesData);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setSelectedInstructionStyle(tempData);
    tempData.length > 0 &&
      tempData.map((val, index) => {
        payloadarray.push({ sort: tempData.length - index, id: val.id });
      });
    setPayloadarraypass(payloadarray);
  };
  const handleName = (e) => {
    setName(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
    setErrorName("");
    setErrorExpressionText("");
    let obj = { ...selectedinstructionstyle };
    let str = e.target.value;
    obj.name = str;
    setSelectedInstructionStyle({ ...obj });
  };
  let num = /^[0-9]+$/;
  const SortFacility = () => {
    let data = payloadarraypass ? payloadarraypass : [];
    makePostRequest(URLS.SORTINSTRUCTIONALSTYLE, null, data, null).then((res) => {
      if (res.code == 200) {
        toast.success(res.message);
        GetAllInstructionalStyle();
      } else if (res.code == 400) {
        setLoader(false);
        toast.error(res.message);
      }
    });
  };

  useEffect(() => {
    if (payloadarraypass.length > 0) {
      SortFacility();
    }
  }, [payloadarraypass]);

  const Addinstructionstylehandler = () => {
    if (name == "") {
      setErrorName("Please enter instruction style name.");
    }
    else {
      AddNewInstructionalStyle();
      setLoader(true);
    }
  };
  const Updateinstructionstylehandler = () => {
    if (selectedinstructionstyle.name.replace(/^\s+|\s+$|\s+(?=\s)/g, "") == "") {
      setErrorName("Please enter instruction style name.");
    }
    else if (isUpdate) {
      handleupdateUser();
    }
  };
  const AddNewInstructionalStyle = () => {
    let data = {
      name: name,
      status: "enable",
    };
    makePostRequest(URLS.ADD_INSTRUCTION_STYLE, null, data, null).then((res) => {
      if (res.code == 201) {
        setName("");
        setSelectedInstructionStyle({ ...selectedinstructionstyle, name: "" });
        toast.success(res.message);
        GetAllInstructionalStyle();
        setIsUpdate(false);
        setSelectedInstructionStyle({});
      } else if (res.code == 400) {
        toast.error(res.message);
        setLoader(false);
      }
    });
  };
  useEffect(() => {
    GetAllInstructionalStyle();
  }, []);

  const GetAllInstructionalStyle = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_INSTRUCTION_STYLE, {}, null)
      .then((res) => {
        if (res.code == 200) {
          setRolesData(res.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditButton = (val) => {
    setErrorName("");
    setIsUpdate(true);
    let obj = { id: val.id, name: val.name };
    setSelectedInstructionStyle(obj);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleupdateUser = () => {
    let data = {
      id: selectedinstructionstyle.id,
      name: selectedinstructionstyle.name.replace(/^\s+|\s+$|\s+(?=\s)/g, ""),
    };
    makePostRequest(URLS.UPDATE_INSTRUCTION_STYLE, null, data, null).then((res) => {
      if (res.code == 200) {
        setName("");
        setSelectedInstructionStyle({ ...selectedinstructionstyle, name: "" });
        toast.success(res.message);
        setLoader(true);
        GetAllInstructionalStyle();
        setIsUpdate(false);
        setSelectedInstructionStyle({});
      } else if (res.code === 400) {
        toast.error(res.message);
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="Instruction Style"></Head>
      <Content>
        <ToastContainer />
        <h3 className="nk-block-title page-title mb5 pb-4">Manage Instructional Styles</h3>
        {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "250px" }}>
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : (
          <Card className="card-bordered">
            <div className="card-inner-lg">
              <BlockHeadContent>
                <BlockHead size="lg">
                  <div className="row mb-4">
                    <div className="col-lg-4">
                      {isUpdate === false ? (
                        <Label className="form-label">Add Instructional Style</Label>
                      ) : (
                        <Label className="form-label">Edit Instructional Styles</Label>
                      )}

                      <div className="form-control-wrap">
                        <Input
                          name="name"
                          placeholder="Instructional Style"
                          type="text"
                          value={selectedinstructionstyle.name}
                          onChange={handleName}
                        />
                        <span className="error"> {errorname} </span>
                        <span className="error"> {expressionTextError} </span>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <Label className="form-label">&nbsp;</Label>
                      {isUpdate === false ? (
                        <ul className="">
                          <li>
                            <button
                              className="btn btn-primary btn-md"
                              type="submit"
                              onClick={Addinstructionstylehandler}
                            >
                              Add{" "}
                            </button>
                          </li>
                        </ul>
                      ) : (
                        <ul className="">
                          <li>
                            <button
                              className="btn btn-primary btn-md"
                              type="submit"
                              onClick={Updateinstructionstylehandler}
                            >
                              Update{" "}
                            </button>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  {isUpdate === false && (
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <table className="table borderd">
                        <thead>
                          <tr className="p-3 bg-white border border-light round-lg mb-3">
                            <th />
                            <th>#</th>
                            <th>Instructional Styles</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <Droppable droppableId="droppable-1">
                          {(provider) => (
                            <tbody className="text-capitalize" ref={provider.innerRef} {...provider.droppableProps}>
                              {rolesData?.map((user, index) => (
                                <Draggable
                                  key={user.name}
                                  draggableId={user.name}
                                  index={index}
                                >
                                  {(provider) => (
                                    <tr
                                      {...provider.draggableProps}
                                      ref={provider.innerRef}
                                      className="p-3 bg-white border border-light round-lg mb-3"
                                    >
                                      <td {...provider.dragHandleProps}>
                                        <div className=" mt-2 mb-2">
                                          <FontAwesomeIcon icon={faGripVertical} />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        <div className=" mt-2 mb-2">{index + 1}</div>
                                      </td>
                                      <td>
                                        <div className="user-card mt-2 mb-2">
                                          <div className="user-info">
                                            <span className="userName">{user.name} </span>
                                          </div>
                                        </div>
                                      </td>

                                      <td>
                                        <>
                                          {user.status == "enable" && (
                                            <div className="custom-control custom-switch">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={user.id}
                                                onClick={() => handleswitch(user.id, "disable", index)}
                                                checked
                                              />
                                              <label className="custom-control-label" htmlFor={user.id}></label>
                                            </div>
                                          )}
                                          {user.status == "disable" && (
                                            <div className="custom-control custom-switch">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={user.id}
                                                onClick={() => handleswitch(user.id, "enable", index)}
                                              />
                                              <label className="custom-control-label" htmlFor={user.id}></label>
                                            </div>
                                          )}
                                        </>
                                      </td>
                                      <td>
                                        <div className="user-card mt-2 mb-2">
                                          <button
                                            className="dropdown-item"
                                            onClick={() => {
                                              handleEditButton(user);
                                            }}
                                          >
                                            <Icon name="edit"></Icon>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                              {provider.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                    </DragDropContext>
                  )}
                </BlockHead>
              </BlockHeadContent>
            </div>
          </Card>
        )}
      </Content>
    </React.Fragment>
  );
}

export default ManageInstructionStyle;

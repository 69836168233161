import React, { useEffect, useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { BASE_URL, getHeaders, makePostRequest, imageUrl } from "../../../../service/api";
import { URLS } from "../../../../constants/urls";
import axios from "axios";
import { getUserDetailsFromToken } from "../../../../service/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const User = (props) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);
  const [currentUserAuthData, setCurrentUserAuthData] = useState({});
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleSignout = () => {
    let data = {
      id: currentUserAuthData.id || 1,
    };
    makePostRequest(URLS.LOGOUT, null, data, null).then((res) => {
      setLoader(true);
      sessionStorage.removeItem("auth-token");
      // setTimeout(() => {
      //   window.history.pushState(
      //     `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/auth-login"}`,
      //     "auth-login",
      //     `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/auth-login"}`
      //   );
      //   window.location.reload();
      // }, 2000); 
        toast.success(res.message);
    });
  };

  const getCurrentUserData = () => {
    let userAuth = getUserDetailsFromToken();
    if (userAuth && userAuth.id && userAuth.name && userAuth.email) {
      setCurrentUserAuthData(userAuth);
    }
    //console.log(userAuth)
    let data = {
      id: userAuth.id,
    };
    makePostRequest(URLS.USER_GET, null, data, null)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCurrentUserData();
  }, []);


  const [file, setFile] = useState(null);
  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };
  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("id", currentUserAuthData.id);
    formData.append("photo", file);
    let response = await axios.post(BASE_URL + URLS.FILE_IMG_UPLOAD, formData, {
      headers: getHeaders({ "Content-Type": "multipart/form-data" }),
    });
    if (response.status == 200) {
      //success
      console.log("fileupload response = = \n", response);
    } else {
      //failed
    }
  }

  return (
    <>
      <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
        <DropdownToggle
          tag="a"
          href="#toggle"
          className="dropdown-toggle"
          onClick={(ev) => {
            ev.preventDefault();
          }}
        >
          <ToastContainer />
          <div className="user-toggle">
            <div className="imguploadBox">
              <img src={imageUrl + data?.photo} alt="Profile images" />
            </div>

            <div className="user-info d-none d-md-block">
              {/* <div className="user-status">{currentUserAuthData.name} </div> */}

              <div className="user-name dropdown-indicator">{data.name}</div>
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
          <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
            <div className="user-card sm">
              <div className="imguploadBox">
                <span>
                  {" "}
                  <img src={imageUrl + data?.photo} alt="Profile images" />{" "}
                </span>
              </div>
              <div className="user-info">
                <span className="lead-text">{data?.name}</span>
                <span className="sub-text">{data?.email}</span>
              </div>
            </div>
          </div>
          <div className="dropdown-inner">
            <LinkList>
              <LinkItem
                link="/user-profile-regular"
                icon="user-alt"
                onClick={toggle}
                data={data}
                currentUser={currentUserAuthData}
              >
                Edit Profile
              </LinkItem>
              <LinkItem link="/user-profile-setting" icon="setting-alt" onClick={toggle}>
                Change Password
              </LinkItem>
            </LinkList>
          </div>
          {/* <div>
      <input type="file" onChange={handleFileUpload} accept="image/png, image/jpeg ,image/gif" />
		 <button onClick={handleSubmit} >Upload</button> 
        </div> */}
          <div className="dropdown-inner">
            <LinkList>
              <LinkItem link="auth-login" onClick={handleSignout}>
                <Icon name="signout"></Icon>
                <span>Log Out</span>
              </LinkItem>
            </LinkList>
          </div>
        </DropdownMenu>
      </Dropdown>
      {loader && (
        <div className="overflowloader" id="preload">
          <div className="circle-square" style={{ marginTop: "300px" }}>
            <div className="red" style={{ background: "#AAC0CE" }}></div>
            <div className="blue" style={{ background: "#265472" }}></div>
            <div className="green" style={{ background: "#F19357" }}></div>
            <div className="yellow" style={{ background: "#F0B285" }}></div>
          </div>
        </div>
      )}
    </>
  );
};

export default User;

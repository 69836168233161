import React, { useState, useEffect, useRef } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { URLS } from "../../../constants/urls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import { Card, Input } from "reactstrap";
import { ReactDataTable, Icon } from "../../../components/Component";
import { Link } from "react-router-dom";
import { makePostRequest, makeGetRequest, imageUrl } from "../../../service/api";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { FaFilter } from "react-icons/fa";
import Select from "react-select";
import { S3_BUCKET_URL } from "../../../constants";
const ExcelJS = require("exceljs");
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { CardTitle } from "reactstrap";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import organisation from "../../../assets/images/organisation1.png";
import curriculum from "../../../assets/images/curriculum.png";
import parent from "../../../assets/images/parent.png";
import Funding from "../../../assets/images/sr-img5.png";
import Experiences from "../../../assets/images/ph2 (1).png";
import StudentSupport from "../../../assets/images/ph3.png";
import Facility from "../../../assets/images/facility.png";
import { checkImageValidity } from "../../../utils/Utils";

const ListResource = () => {
  const [getAllResource, setGetAllResources] = useState([]);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [getAllResourceNew, setgetAllResourceNew] = useState([]);
  const [resourceTypeText, setResourceTypeText] = useState("");
  const [curriculamContentType, setCurriculamContentType] = useState("");
  const [experienceType, setExperienceType] = useState("");
  const [facilityType, setFacilityType] = useState("");
  const [fundingType, setFundingType] = useState("");
  const [resourceType, setResourceType] = useState("");
  const [organizationType, setOrganizationType] = useState("");
  const [studentSupportType, setStudentSupportType] = useState("");
  const [teacherParentSupportType, setTeacherParentSupportType] = useState("");
  const [timeFrameTypeCariculam, setTimeFrameTypeCariculam] = useState("");
  const [timeFrameTypeExperience, setTimeFrameTypeExperience] = useState("");
  const [state, setState] = useState({});
  const [getAllFacilityResourcePage, setGetAllFacilityResourcePage] = useState([]);
  const [getAllFundingResourcePage, setGetAllFundingResourcePage] = useState([]);
  const [getAllOrganizationResourcePage, setGetAllOrganizationResourcePage] = useState([]);
  const [getAllExperienceResourcePage, setGetAllExperienceResourcePage] = useState([]);
  const [getAllStudentSupportResourcePage, setGetAllStudentSupportResourcePage] = useState([]);
  const [getTeacherParentSupportResourcePage, setGetTeacherParentSupportResourcePage] = useState([]);
  const [getInstructionStyleResourcePage, setGetInstructionStyleResourcePage] = useState([]);
  const [getCustomerParentData, setGetCustomerParentData] = useState([]);
  const [getChildlistData, setGetChildlistData] = useState([]);
  const [allLearningPiller, setAllLearningPiller] = useState([]);
  const [parentid, setparentid] = useState("");
  const [pillerId, setPillerId] = useState([]);
  const [childId, setChildId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  let [count, setCount] = useState();
  const [pageCount, setPageCount] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedfromdate, setSelectedfromDate] = useState("");
  const [selectedtodate, setSelectedToDate] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [toDateError, settoDateError] = useState("");
  const [parentDropdown, setParentDropdown] = useState([]);
  const [dropdownPage, setDropdownPage] = useState(1);
  const [selectedParent, setselectedParent] = useState();
  const [countScroll, setCountScroll] = useState(0);
  const [resourcestate, setresourcestate] = useState("");
  const [resourcedeletestate, setresourcedeletestate] = useState("");
  const [limitvalue, setlimitvalue] = useState();
  const [sortresource, setSortResource] = useState("");
  const [learningPillarData, setLearningPillarData] = useState([]);
  const [learningPillarIds, setLearningPillarIds] = useState([]);
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  let learning_pillars_arr = [];
  const [showArrow, setShowArrow] = useState(false);
  const tableRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const [arrowDisableRight, setArrowDisableRight] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchByISBN, setSearchByISBN] = useState("");
  const [instructionalStyleType, setInstructionalStyleType] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [saveserve, setSaveServe] = useState("");
  const [clearState, setClearState] = useState(false);
  const previousPage = useRef();
  const [displayvalueSelectFilter, setDisplayValueSelectFilter] = useState(true);
  const [displayvalueParentCustomer, setDisplayvalueParentCustomer] = useState(true);
  let resourcedata = JSON.parse(sessionStorage.getItem("resource"));
  const ShowHideSelect = () => {
    setShow(!show);
  };
  function handleclickarrow() {
    window.scrollBy(0, 10);
    setShowArrow(!showArrow);
  }
  const [columns, setColumns] = useState([
    // const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 10,
      minWidth: "100px",
      style: { minWidth: "100px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}
          </div>
        </>
      ),
    },

    {
      name: "Date Registered",
      selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
      grow: 10,
      minWidth: "150px",
      style: { minWidth: "150px" },
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{moment(row.created_at).format("MM-DD-YYYY")}</span>
            {row.is_review == "yes" ? (
              <div className="user-card mt-0 mb-0">
                <button type="button" className="badge badge-success" style={{ background: "#28a745" }}>
                  <span>Reviewed</span>
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
    },
    {
      name: "Customer Details",
      selector: (row) =>
        row.tbl_user.firstName
          ? row.tbl_user.firstName
          : "--" + " " + row.tbl_user.lastName
          ? row.tbl_user.lastName
          : "",
      grow: 10,
      // sortable: true,
      minWidth: "200px",
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row.tbl_user?.firstName == null ? <div>--</div> : row.tbl_user?.firstName}{" "}
              {row.tbl_user?.lastName == null ? <div>--</div> : row.tbl_user?.lastName}
            </span>
            <span className="sub-text">{row.tbl_user?.email}</span>
            <span className="sub-text">{row.tbl_user?.phone}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Thumbnail Images",
      selector: (row) => row.resource_title,
      minWidth: "160px",
      grow: 15,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <div className="icon-outer">
              {row?.resource_thumbnail_image ? (
                <img
                  src={imageUrl + row?.resource_thumbnail_image}
                  style={{ width: "80px", height: "80px", borderRadius: "10px" }}
                />
              ) : (
                ""
              )}

              {row?.resource_thumbnail_image == null && row?.imageLink !== null ? (
                <img
                  src={row?.imageLink}
                  style={{ width: "80px", height: "80px", borderRadius: "10px" }}
                  alt="Image Error"
                />
              ) : (
                ""
              )}

              {row?.resource_thumbnail_image == null && row?.imageLink == null && row?.resource_type === 1 ? (
                <img
                  src={organisation}
                  alt="organisation"
                  style={{ width: "80px", height: "80px", borderRadius: "10px", background: "#F9C976", padding: "7px" }}
                />
              ) : (
                ""
              )}
              {row?.resource_thumbnail_image == null && row?.imageLink == null && row?.resource_type === 6 ? (
                <img
                  src={Funding}
                  alt="Funding"
                  style={{ width: "80px", height: "80px", borderRadius: "10px", background: "#82A3A1", padding: "7px" }}
                />
              ) : (
                ""
              )}
              {row?.resource_thumbnail_image == null && row?.imageLink == null && row?.resource_type === 5 ? (
                <img
                  src={parent}
                  alt="Funding"
                  style={{ width: "80px", height: "80px", borderRadius: "10px", background: "#aac0ce", padding: "7px" }}
                />
              ) : (
                ""
              )}
              {row?.resource_thumbnail_image == null && row?.imageLink == null && row?.resource_type === 2 ? (
                <img
                  src={curriculum}
                  alt="Curriculum Content"
                  style={{ width: "80px", height: "80px", borderRadius: "10px", background: "#f19357", padding: "7px" }}
                />
              ) : (
                ""
              )}

              {row?.resource_thumbnail_image == null && row?.imageLink == null && row?.resource_type === 3 ? (
                <img
                  src={Experiences}
                  alt="Experiences"
                  style={{ width: "80px", height: "80px", borderRadius: "10px", background: "#57886c", padding: "7px" }}
                />
              ) : (
                ""
              )}

              {row?.resource_thumbnail_image == null && row?.imageLink == null && row?.resource_type === 4 ? (
                <img
                  src={StudentSupport}
                  alt="Student Support"
                  style={{ width: "80px", height: "80px", borderRadius: "10px", background: "#265472", padding: "7px" }}
                />
              ) : (
                ""
              )}

              {row?.resource_thumbnail_image == null && row?.imageLink == null && row?.resource_type === 7 ? (
                <img
                  src={Facility}
                  alt="Facility"
                  style={{ width: "80px", height: "80px", borderRadius: "10px", background: "#91CBCE", padding: "7px" }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "Resource Details",
      selector: (row) => row.resource_title,
      minWidth: "150px",
      grow: 10,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.resource_title ? row?.resource_title : "Not Available"}</span>
            <span className="sub-text">
              {row?.tbl_pillar_resource_mappings?.map((item) => (
                <div>
                  <img
                    src={imageUrl + item.tbl_learning_pillar_master?.image}
                    style={{ width: "20px", height: "20px" }}
                  ></img>
                </div>
              ))}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Resource Type",
      selector: (row) => row.tbl_resource_type_master?.name,
      grow: 10,
      minWidth: "130px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.tbl_resource_type_master?.name ? row.tbl_resource_type_master?.name : "--"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Priority of Resource",
      selector: (row) => "",
      grow: 10,
      minWidth: "180px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.priority_of_resource}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Affiliate Resource",
      selector: (row) => "",
      grow: 10,
      minWidth: "180px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.affiliate_resource === "1" ? "Yes" : "No"}</span>
          </div>
        </div>
      ),
    },

    {
      name: "Resource URL",
      selector: (row) => "",
      grow: 10,
      minWidth: "280px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.resource_url ? row?.resource_url : "Not Available"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Total Searches",
      selector: (row) => "",
      grow: 10,
      minWidth: "130px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.total_search ? row?.total_search : "0"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Total Views",
      selector: (row) => "",
      grow: 10,
      minWidth: "130px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.total_time_viewed ? row?.total_time_viewed : "0"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Description",
      selector: (row) => "",
      grow: 10,
      minWidth: "130px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.description ? "Available" : "Not Available"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => "",
      grow: 10,
      // sortable: true,
      cell: (row, index) => (
        <>
          {row?.status == "publish" && (
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id={row.id}
                onClick={() => handlebutton(row.id, "unpublish", index)}
                checked
              />
              <label className="custom-control-label" htmlFor={row.id}></label>
            </div>
          )}
          {row?.status == "unpublish" && (
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id={row.id}
                onClick={() => handlebutton(row.id, "publish", index)}
              />
              <label className="custom-control-label" htmlFor={row.id}></label>
            </div>
          )}
          {row?.status === "under_review" && (
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id={row.id}
                onClick={() => handlebutton(row.id, "publish", index)}
              />
              <label className="custom-control-label" htmlFor={row.id}></label>
            </div>
          )}
        </>
      ),
    },
    {
      name: "Keywords",
      selector: (row) => "",
      grow: 10,
      minWidth: "130px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row?.tbl_resource_keywords && row?.tbl_resource_keywords.length > 0
                ? row?.tbl_resource_keywords.map((temp) => temp.keyword).join(", ")
                : "Not Available"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Grade",
      selector: (row) => "",
      grow: 10,
      minWidth: "130px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row?.tbl_grade_resource_mappings && row?.tbl_grade_resource_mappings.length > 0
                ? row?.tbl_grade_resource_mappings.map((temp) => temp.tbl_grade_level.name).join(", ")
                : "--"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "ISBN Number",
      selector: (row) => "",
      grow: 10,
      minWidth: "130px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            {row?.resource_type === 2 ? <span className="sub-text">{row.isbn ? row.isbn : "--"}</span> : "--"}
          </div>
        </div>
      ),
    },
    {
      name: "Show on Home",
      selector: (row) => row.show_on_home_page,
      grow: 15,
      minWidth: "160px",
      // sortable: true,
      cell: (row, index) => (
        <>
          {row.show_on_home_page == "yes" && (
            <div
              className="custom-control custom-switch"
              onClick={() => HandleShowonHomePageButton(row.id, "no", index)}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id={row.id}
                checked
              />
              <label className="custom-control-label"></label>
            </div>
          )}
          {row.show_on_home_page == "no" && (
            <div
              className="custom-control custom-switch"
              onClick={() => HandleShowonHomePageButton(row.id, "yes", index)}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id={row.id}
              />
              <label className="custom-control-label"></label>
            </div>
          )}
        </>
      ),
    },

    {
      name: "Action",
      selector: (row) => row?.status,
      grow: 10,

      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/view-resource/${row.id}`}>
            {" "}
            <button className="dropdown-item btn btn-md">
              <em className="icon ni ni-eye"></em>
            </button>
            {sessionStorage.setItem("tab", "")}
          </Link>
          <Link to={`/editResource-page/${row.id}`}>
            {" "}
            <button className="dropdown-item btn btn-md">
              <em className="icon ni ni-edit"></em>
            </button>
          </Link>

          <button className="dropdown-item btn btn-md" onClick={() => handleOpen(row)}>
            <i className="fa fa-trash-o" style={{ fontSize: "17px", padding: "1px", color: "#2566e8" }}></i>
          </button>
        </div>
      ),
    },
  ]);

  useEffect(() => {
    setColumns([
      {
        name: "#",
        selector: (row) => row.id,
        grow: 10,
        minWidth: "100px",
        style: { minWidth: "100px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPage == 1
                ? index + 1
                : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}
            </div>
          </>
        ),
      },

      {
        name: "Date Registered",
        selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
        grow: 10,
        minWidth: "150px",
        style: { minWidth: "150px" },
        // sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">{moment(row.created_at).format("MM-DD-YYYY")}</span>
              {row.is_review == "yes" ? (
                <div className="user-card mt-0 mb-0">
                  <button type="button" className="badge badge-success" style={{ background: "#28a745" }}>
                    <span>Reviewed</span>
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ),
      },
      {
        name: "Customer Details",
        selector: (row) =>
          row.tbl_user.firstName
            ? row.tbl_user.firstName
            : "--" + " " + row.tbl_user.lastName
            ? row.tbl_user.lastName
            : "",
        grow: 10,
        // sortable: true,
        minWidth: "200px",
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">
                {row.tbl_user?.firstName == null ? <div>--</div> : row.tbl_user?.firstName}{" "}
                {row.tbl_user?.lastName == null ? <div>--</div> : row.tbl_user?.lastName}
              </span>
              <span className="sub-text">{row.tbl_user?.email}</span>
              <span className="sub-text">{row.tbl_user?.phone}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Thumbnail Images",
        selector: (row) => row.resource_title,
        minWidth: "160px",
        grow: 15,
        // sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <div className="icon-outer">
                {row?.resource_thumbnail_image ? (
                  <img
                    src={imageUrl + row?.resource_thumbnail_image}
                    style={{ width: "80px", height: "80px", borderRadius: "10px" }}
                  />
                ) : (
                  ""
                )}

                {row?.resource_thumbnail_image == null && row?.imageLink !== null ? (
                  <img
                    src={row?.imageLink}
                    style={{ width: "80px", height: "80px", borderRadius: "10px" }}
                    alt="Image Error"
                  />
                ) : (
                  ""
                )}

                {row?.resource_thumbnail_image == null && row?.imageLink == null && row?.resource_type === 1 ? (
                  <img
                    src={organisation}
                    alt="organisation"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "10px",
                      background: "#F9C976",
                      padding: "7px",
                    }}
                  />
                ) : (
                  ""
                )}
                {row?.resource_thumbnail_image == null && row?.imageLink == null && row?.resource_type === 6 ? (
                  <img
                    src={Funding}
                    alt="Funding"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "10px",
                      background: "#82A3A1",
                      padding: "7px",
                    }}
                  />
                ) : (
                  ""
                )}
                {row?.resource_thumbnail_image == null && row?.imageLink == null && row?.resource_type === 5 ? (
                  <img
                    src={parent}
                    alt="Funding"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "10px",
                      background: "#aac0ce",
                      padding: "7px",
                    }}
                  />
                ) : (
                  ""
                )}
                {row?.resource_thumbnail_image == null && row?.imageLink == null && row?.resource_type === 2 ? (
                  <img
                    src={curriculum}
                    alt="Curriculum Content"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "10px",
                      background: "#f19357",
                      padding: "7px",
                    }}
                  />
                ) : (
                  ""
                )}

                {row?.resource_thumbnail_image == null && row?.imageLink == null && row?.resource_type === 3 ? (
                  <img
                    src={Experiences}
                    alt="Experiences"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "10px",
                      background: "#57886c",
                      padding: "7px",
                    }}
                  />
                ) : (
                  ""
                )}

                {row?.resource_thumbnail_image == null && row?.imageLink == null && row?.resource_type === 4 ? (
                  <img
                    src={StudentSupport}
                    alt="Student Support"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "10px",
                      background: "#265472",
                      padding: "7px",
                    }}
                  />
                ) : (
                  ""
                )}

                {row?.resource_thumbnail_image == null && row?.imageLink == null && row?.resource_type === 7 ? (
                  <img
                    src={Facility}
                    alt="Facility"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "10px",
                      background: "#91CBCE",
                      padding: "7px",
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ),
      },
      {
        name: "Resource Details",
        selector: (row) => row.resource_title,
        minWidth: "150px",
        grow: 10,
        // sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row?.resource_title ? row?.resource_title : "Not Available"}</span>
              {/* <span className="sub-text">{row.tbl_pillar_resource_mappings.map((item)=>item.tbl_learning_pillar_master.name).join(", ")}</span> */}
              <span className="sub-text">
                {row?.tbl_pillar_resource_mappings?.map((item) => (
                  <div>
                    <img
                      src={`${imageUrl}${item.tbl_learning_pillar_master?.image}`}
                      style={{ width: "18px", margin: "2px" }}
                    ></img>
                  </div>
                ))}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Resource Type",
        selector: (row) => row.tbl_resource_type_master?.name,
        grow: 10,
        minWidth: "130px",

        // sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">
                {row.tbl_resource_type_master?.name ? row.tbl_resource_type_master?.name : "--"}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Priority of Resource",
        selector: (row) => "",
        grow: 10,
        minWidth: "180px",

        // sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row.priority_of_resource}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Affiliate Resource",
        selector: (row) => "",
        grow: 10,
        minWidth: "180px",
        // sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row?.affiliate_resource === "1" ? "Yes" : "No"}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Resource URL",
        selector: (row) => "",
        grow: 10,
        minWidth: "280px",
        // sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row?.resource_url ? row?.resource_url : "Not Available"}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Total Searches",
        selector: (row) => "",
        grow: 10,
        minWidth: "130px",

        // sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row?.total_search ? row?.total_search : "0"}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Total Views",
        selector: (row) => "",
        grow: 10,
        minWidth: "130px",
        // sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row?.total_time_viewed ? row?.total_time_viewed : "0"}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Description",
        selector: (row) => "",
        grow: 10,
        minWidth: "130px",
        // sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row?.description ? "Available" : "Not Available"}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Keywords",
        selector: (row) => "",
        grow: 10,
        minWidth: "130px",
        // sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">
                {row?.tbl_resource_keywords && row?.tbl_resource_keywords.length > 0
                  ? row?.tbl_resource_keywords.map((temp) => temp.keyword).join(", ")
                  : "Not Available"}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Grade",
        selector: (row) => "",
        grow: 10,
        minWidth: "130px",
        // sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">
                {row?.tbl_grade_resource_mappings && row?.tbl_grade_resource_mappings.length > 0
                  ? row?.tbl_grade_resource_mappings.map((temp) => temp.tbl_grade_level.name).join(", ")
                  : "--"}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "ISBN Number",
        selector: (row) => "",
        grow: 10,
        minWidth: "130px",
        // sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              {row.tbl_resource_type_master?.name == "Curriculum Content" ? (
                <span className="sub-text">{row.isbn ? row.isbn : "--"}</span>
              ) : (
                "--"
              )}
            </div>
          </div>
        ),
      },
      {
        name: "Status",
        selector: (row) => "",
        grow: 10,
        // sortable: true,
        cell: (row, index) => (
          <>
            {row?.status == "publish" && (
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={row.id}
                  onClick={() => handlebutton(row.id, "unpublish", index)}
                  checked
                />
                <label className="custom-control-label" htmlFor={row.id}></label>
              </div>
            )}
            {row?.status == "unpublish" && (
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={row.id}
                  onClick={() => handlebutton(row.id, "publish", index)}
                />
                <label className="custom-control-label" htmlFor={row.id}></label>
              </div>
            )}
            {row?.status === "under_review" && (
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={row.id}
                  onClick={() => handlebutton(row.id, "publish", index)}
                />
                <label className="custom-control-label" htmlFor={row.id}></label>
              </div>
            )}
          </>
        ),
      },
      {
        name: "Show on Home",
        selector: (row) => row.show_on_home_page,
        grow: 15,
        minWidth: "160px",
        // sortable: true,
        cell: (row, index) => (
          <>
            {row.show_on_home_page == "yes" && (
              <div
                className="custom-control custom-switch"
                onClick={() => HandleShowonHomePageButton(row.id, "no", index)}
              >
                <input type="checkbox" className="custom-control-input" id={row.id} checked />
                <label className="custom-control-label"></label>
              </div>
            )}
            {row.show_on_home_page == "no" && (
              <div
                className="custom-control custom-switch"
                onClick={() => HandleShowonHomePageButton(row.id, "yes", index)}
              >
                <input type="checkbox" className="custom-control-input" id={row.id} />
                <label className="custom-control-label"></label>
              </div>
            )}
          </>
        ),
      },

      {
        name: "Action",
        selector: (row) => row?.status,
        grow: 10,

        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <Link to={`/view-resource/${row.id}`}>
              {" "}
              <button className="dropdown-item btn btn-md">
                <em className="icon ni ni-eye"></em>
              </button>
              {sessionStorage.setItem("tab", "")}
            </Link>
            <Link to={`/editResource-page/${row.id}`}>
              {" "}
              <button className="dropdown-item btn btn-md">
                <em className="icon ni ni-edit"></em>
              </button>
            </Link>

            <button className="dropdown-item btn btn-md" onClick={() => handleOpen(row)}>
              <i className="fa fa-trash-o" style={{ fontSize: "17px", padding: "1px", color: "#2566e8" }}></i>
            </button>
          </div>
        ),
      },
    ]);
    getresourcefun();
  }, [sortresource, limitvalue, currentPage]);
  // console.log(getAllResource,"getAllResource")
  //close  confirmation modal
  const [resourceid, setResourceid] = useState("");
  const [userId, setUserId] = useState("");
  const handleClose = () => setOpen(false);

  const handleOpen = (row) => {
    setOpen(!open);
    setResourceid(row?.id);
    setUserId(row?.tbl_user?.id);
  };

  const handleDelete = () => {
    let data = {
      id: resourceid,
      user_id: userId,
    };

    makePostRequest(URLS.DELETE_RESOURCE, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          toast.success(res.message);
          getresourcefun();
          setOpen(false);
        } else {
          toast.error(res.message);
          getresourcefun();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlebutton = (id, isSelected, index) => {
    let data = {
      id: id,
      status: isSelected,
    };
    const newArray = Array.from(getAllResource);
    if (newArray[index] && newArray[index].status) {
      newArray[index].status = isSelected === "publish" ? "unpublish" : "publish";
      setGetAllResources(newArray);
    }
    makePostRequest(URLS.UPDATE_ADMIN_RESOURCE, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          toast.success(res.message);
          getresourcefun();
        } else {
          toast.error(res.message);
          getresourcefun();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
      if (element.scrollLeft === element.scrollWidth - element.clientWidth) {
        setArrowDisableRight(true);
      } else {
        setArrowDisableRight(false);
      }
    }, speed);
  };

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const HandleColumn = () => {
    setIsOpen(!isOpen);
  };

  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    setCurrentPage(1);
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: e.target.value,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        // startDate: selectedfromdate,
        // endDate:selectedtodate,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        customerType: customerType,
        affiliateres: affiliateResource,
      })
    );
    //  setCurrentPage(1);
  };
  const HandleSortResource = (e) => {
    resourcedata.pagenumberresource = "";
    setCurrentPage(1);

    setSortResource(e.target.value);
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: e.target.value,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        customerType: customerType,
        affiliateres: affiliateResource,
      })
    );
    // setCurrentPage(1);
  };

  useEffect(() => {
    setLoader(true);
    setlimitvalue(resourcedata.limitvalueresource);
    setCurrentPage(resourcedata?.pagenumberresource ? resourcedata?.pagenumberresource : 1);
    setSortResource(resourcedata.sortvalueresource);
    setSaveServe(resourcedata.resourceMode);
    setparentid(resourcedata.parentId);
    setChildId(resourcedata.childId);
    setResourceType(resourcedata.resourceType);
    setCurriculamContentType(resourcedata.curriculumContentType);
    setExperienceType(resourcedata.experienceType);
    setFacilityType(resourcedata.facilityType);
    setFundingType(resourcedata.fundingType);
    setSearchByISBN(resourcedata.isbnSearch);
    setSearch(resourcedata.search);
    setSelectedToDate(resourcedata.endDate);
    setSelectedfromDate(resourcedata.startDate);
    setLearningPillarIds(resourcedata.learningPillarVal);
    setInstructionalStyleType(resourcedata.instructionalStyleType);
    setTimeFrameTypeCariculam(resourcedata.timeFrameTypeCariculam);
    setTeacherParentSupportType(resourcedata.teacherParentSupportType);
    setStudentSupportType(resourcedata.studentSupportType);
    setOrganizationType(resourcedata.organizationType);
    setCustomerType(resourcedata.customerType);
    setShowonHome(resourcedata.showonHomeAvailable);
    setUrlAv(resourcedata.urlAvailable);
    setImageAv(resourcedata.imgAvailable);
    setDescriptionAv(resourcedata.descriptionAvailable);
    setTitleAv(resourcedata.titleAvailable);
    setReviewdresource(resourcedata.resourceReview);
    setresourcedeletestate(resourcedata.statusDelete);
    setresourcestate(resourcedata.status);
    setAffiliateResource(resourcedata.affiliateres);
    getresourcefun();

    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, []);

  const handleLearningPillar = (learningPillarVal) => {
    setLearningPillarIds(learningPillarVal);
    setShowArrow(!showArrow);

    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarVal,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        customerType: customerType,
        affiliateres: affiliateResource,
      })
    );
  };
  const HandleShowonHomePageButton = (id1, isSelected1, index) => {
    let data = {
      id: id1,
      show_on_home_page: isSelected1,
    };
    const newArray1 = Array.from(getAllResource);
    if (newArray1[index] && newArray1[index].show_on_home_page) {
      newArray1[index].show_on_home_page = isSelected1 === "yes" ? "no" : "yes";
      setGetAllResources(newArray1);
    }
    makePostRequest(URLS.updateResourceStatus, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          toast.success(res.message);
          getresourcefun();
        } else {
          toast.error(res.message);
          getresourcefun();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllUserType = () => {
    makeGetRequest(URLS.GET_ALL_RESOURCE_TYPE, {}, null)
      .then((res) => {
        if (res.code == 200) {
          setgetAllResourceNew(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlestate = (e) => {
    setresourcestate(e.target.value);

    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: e.target.value,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        customerType: customerType,
        affiliateres: affiliateResource,
      })
    );
  };
  const handledeletestate = (e) => {
    setresourcedeletestate(e.target.value);
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: e.target.value,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        customerType: customerType,
        affiliateres: affiliateResource,
      })
    );
  };

  const HandleFromDate = (e) => {
    setSelectedfromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");

    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,

        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        customerType: customerType,
        affiliateres: affiliateResource,
        // startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: e ? moment(e).format("MM-DD-YYYY") : "",
      })
    );
  };
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }

  const handleInstructionalStyle = (e) => {
    setInstructionalStyleType(e.target.value);
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: e.target.value,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        customerType: customerType,
        affiliateres: affiliateResource,
      })
    );
  };

  const handleCustomerType = (e) => {
    setCustomerType(e.target.value);
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        customerType: e.target.value,
        affiliateres: affiliateResource,
      })
    );
  };

  const HandleToDate = (e) => {
    // const todate =e;
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: e ? moment(e).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        affiliateres: affiliateResource,
      })
    );
  };

  const handleSearchTitle = (e) => {
    setSearch(e.target.value);

    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: e.target.value,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        affiliateres: affiliateResource,
      })
    );
  };

  const handleISBNSearch = (e) => {
    setSearchByISBN(e.target.value);

    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: e.target.value,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        affiliateres: affiliateResource,
      })
    );
  };

  const handleResourceType = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: e.target.value,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        affiliateres: affiliateResource,
      })
    );

    if (name === "resourceType") {
      let index = e.nativeEvent.target.selectedIndex;
      let label = e.nativeEvent.target[index].text;
      setResourceTypeText(label);
      setResourceType(value);
    } else if (name === "curriculamContentType") {
      setCurriculamContentType(value);
      sessionStorage.setItem(
        "resource",
        JSON.stringify({
          resourceMode: saveserve,
          limitvalueresource: limitvalue,
          // pagenumberresource: currentPage,
          sortvalueresource: sortresource,
          parentId: parentid,
          childId: childId,
          resourceType: resourceType,
          curriculumContentType: value,
          experienceType: experienceType,
          facilityType: facilityType,
          fundingType: fundingType,
          organizationType: organizationType,
          studentSupportType: studentSupportType,
          teacherParentSupportType: teacherParentSupportType,
          timeframe: timeFrameTypeCariculam,
          instructionalStyleType: instructionalStyleType,
          learningPillarVal: learningPillarIds,
          startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
          endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
          search: search,
          isbnSearch: searchByISBN,
          status: resourcestate,
          statusDelete: resourcedeletestate,
          resourceReview: reviewdresource,
          titleAvailable: titleAv,
          descriptionAvailable: descriptionAv,
          imgAvailable: imageAv,
          urlAvailable: urlAv,
          showonHomeAvailable: showonHome,
          affiliateres: affiliateResource,
        })
      );
    } else if (name === "experienceType") {
      setExperienceType(value);
      sessionStorage.setItem(
        "resource",
        JSON.stringify({
          resourceMode: saveserve,
          limitvalueresource: limitvalue,
          // pagenumberresource: currentPage,
          sortvalueresource: sortresource,
          parentId: parentid,
          childId: childId,
          resourceType: resourceType,
          curriculumContentType: curriculamContentType,
          experienceType: value,
          facilityType: facilityType,
          fundingType: fundingType,
          organizationType: organizationType,
          studentSupportType: studentSupportType,
          teacherParentSupportType: teacherParentSupportType,
          timeframe: timeFrameTypeCariculam,
          instructionalStyleType: instructionalStyleType,
          learningPillarVal: learningPillarIds,
          startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
          endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
          search: search,
          isbnSearch: searchByISBN,
          status: resourcestate,
          statusDelete: resourcedeletestate,
          resourceReview: reviewdresource,
          titleAvailable: titleAv,
          descriptionAvailable: descriptionAv,
          imgAvailable: imageAv,
          urlAvailable: urlAv,
          showonHomeAvailable: showonHome,
          affiliateres: affiliateResource,
        })
      );
    } else if (name === "facilityType") {
      setFacilityType(value);
      sessionStorage.setItem(
        "resource",
        JSON.stringify({
          resourceMode: saveserve,
          limitvalueresource: limitvalue,
          // pagenumberresource: currentPage,
          sortvalueresource: sortresource,
          parentId: parentid,
          childId: childId,
          resourceType: resourceType,
          curriculumContentType: curriculamContentType,
          experienceType: experienceType,
          facilityType: value,
          fundingType: fundingType,
          organizationType: organizationType,
          studentSupportType: studentSupportType,
          teacherParentSupportType: teacherParentSupportType,
          timeframe: timeFrameTypeCariculam,
          instructionalStyleType: instructionalStyleType,
          learningPillarVal: learningPillarIds,
          startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
          endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
          search: search,
          isbnSearch: searchByISBN,
          status: resourcestate,
          statusDelete: resourcedeletestate,
          resourceReview: reviewdresource,
          titleAvailable: titleAv,
          descriptionAvailable: descriptionAv,
          imgAvailable: imageAv,
          urlAvailable: urlAv,
          showonHomeAvailable: showonHome,
          affiliateres: affiliateResource,
        })
      );
    } else if (name === "fundingType") {
      setFundingType(value);
      sessionStorage.setItem(
        "resource",
        JSON.stringify({
          resourceMode: saveserve,
          limitvalueresource: limitvalue,
          // pagenumberresource: currentPage,
          sortvalueresource: sortresource,
          parentId: parentid,
          childId: childId,
          resourceType: resourceType,
          curriculumContentType: curriculamContentType,
          experienceType: experienceType,
          facilityType: facilityType,
          fundingType: value,
          organizationType: organizationType,
          studentSupportType: studentSupportType,
          teacherParentSupportType: teacherParentSupportType,
          timeframe: timeFrameTypeCariculam,
          instructionalStyleType: instructionalStyleType,
          learningPillarVal: learningPillarIds,
          startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
          endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
          search: search,
          isbnSearch: searchByISBN,
          status: resourcestate,
          statusDelete: resourcedeletestate,
          resourceReview: reviewdresource,
          titleAvailable: titleAv,
          descriptionAvailable: descriptionAv,
          imgAvailable: imageAv,
          urlAvailable: urlAv,
          showonHomeAvailable: showonHome,
          affiliateres: affiliateResource,
        })
      );
    } else if (name === "organizationType") {
      setOrganizationType(value);
      sessionStorage.setItem(
        "resource",
        JSON.stringify({
          resourceMode: saveserve,
          limitvalueresource: limitvalue,
          // pagenumberresource: currentPage,
          sortvalueresource: sortresource,
          parentId: parentid,
          childId: childId,
          resourceType: resourceType,
          curriculumContentType: curriculamContentType,
          experienceType: experienceType,
          facilityType: facilityType,
          fundingType: fundingType,
          organizationType: value,
          studentSupportType: studentSupportType,
          teacherParentSupportType: teacherParentSupportType,
          timeframe: timeFrameTypeCariculam,
          instructionalStyleType: instructionalStyleType,
          learningPillarVal: learningPillarIds,
          startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
          endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
          search: search,
          isbnSearch: searchByISBN,
          status: resourcestate,
          statusDelete: resourcedeletestate,
          resourceReview: reviewdresource,
          titleAvailable: titleAv,
          descriptionAvailable: descriptionAv,
          imgAvailable: imageAv,
          urlAvailable: urlAv,
          showonHomeAvailable: showonHome,
          affiliateres: affiliateResource,
        })
      );
    } else if (name === "studentSupportType") {
      setStudentSupportType(value);
      sessionStorage.setItem(
        "resource",
        JSON.stringify({
          resourceMode: saveserve,
          limitvalueresource: limitvalue,
          // pagenumberresource: currentPage,
          sortvalueresource: sortresource,
          parentId: parentid,
          childId: childId,
          resourceType: resourceType,
          curriculumContentType: curriculamContentType,
          experienceType: experienceType,
          facilityType: facilityType,
          fundingType: fundingType,
          organizationType: organizationType,
          studentSupportType: value,
          teacherParentSupportType: teacherParentSupportType,
          timeframe: timeFrameTypeCariculam,
          instructionalStyleType: instructionalStyleType,
          learningPillarVal: learningPillarIds,
          startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
          endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
          search: search,
          isbnSearch: searchByISBN,
          status: resourcestate,
          statusDelete: resourcedeletestate,
          resourceReview: reviewdresource,
          titleAvailable: titleAv,
          descriptionAvailable: descriptionAv,
          imgAvailable: imageAv,
          urlAvailable: urlAv,
          showonHomeAvailable: showonHome,
          affiliateres: affiliateResource,
        })
      );
    } else if (name === "teacherParentSupportType") {
      setTeacherParentSupportType(value);
      sessionStorage.setItem(
        "resource",
        JSON.stringify({
          resourceMode: saveserve,
          limitvalueresource: limitvalue,
          // pagenumberresource: currentPage,
          sortvalueresource: sortresource,
          parentId: parentid,
          childId: childId,
          resourceType: resourceType,
          curriculumContentType: curriculamContentType,
          experienceType: experienceType,
          facilityType: facilityType,
          fundingType: fundingType,
          organizationType: organizationType,
          studentSupportType: studentSupportType,
          teacherParentSupportType: value,
          timeframe: timeFrameTypeCariculam,
          instructionalStyleType: instructionalStyleType,
          learningPillarVal: learningPillarIds,
          startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
          endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
          search: search,
          isbnSearch: searchByISBN,
          status: resourcestate,
          statusDelete: resourcedeletestate,
          resourceReview: reviewdresource,
          titleAvailable: titleAv,
          descriptionAvailable: descriptionAv,
          imgAvailable: imageAv,
          urlAvailable: urlAv,
          showonHomeAvailable: showonHome,
          affiliateres: affiliateResource,
        })
      );
    } else if (name === "timeFrameTypeCariculam") {
      setTimeFrameTypeCariculam(value);
      sessionStorage.setItem(
        "resource",
        JSON.stringify({
          resourceMode: saveserve,
          limitvalueresource: limitvalue,
          // pagenumberresource: currentPage,
          sortvalueresource: sortresource,
          parentId: parentid,
          childId: childId,
          resourceType: resourceType,
          curriculumContentType: curriculamContentType,
          experienceType: experienceType,
          facilityType: facilityType,
          fundingType: fundingType,
          organizationType: organizationType,
          studentSupportType: studentSupportType,
          teacherParentSupportType: teacherParentSupportType,
          timeframe: value,
          instructionalStyleType: instructionalStyleType,
          learningPillarVal: learningPillarIds,
          startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
          endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
          search: search,
          isbnSearch: searchByISBN,
          status: resourcestate,
          statusDelete: resourcedeletestate,
          resourceReview: reviewdresource,
          titleAvailable: titleAv,
          descriptionAvailable: descriptionAv,
          imgAvailable: imageAv,
          urlAvailable: urlAv,
          showonHomeAvailable: showonHome,
          affiliateres: affiliateResource,
        })
      );
    } else if (name === "timeFrameTypeExperience") {
      setTimeFrameTypeExperience(value);
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const [reviewdresource, setReviewdresource] = useState("");
  const [titleAv, setTitleAv] = useState("");
  const [descriptionAv, setDescriptionAv] = useState("");
  const [imageAv, setImageAv] = useState("");
  const [urlAv, setUrlAv] = useState("");
  const [ISBNAv, setISBNAv] = useState("");
  const [showonHome, setShowonHome] = useState("");
  const [affiliateResource, setAffiliateResource] = useState("");

  const handleSaveServe = (e) => {
    setSaveServe(e.target.value);
    //  setCurrentPage(1)
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: e.target.value,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        affiliateres: affiliateResource,
      })
    );
  };

  const handleReviewResource = (e) => {
    setReviewdresource(e.target.value);
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: e.target.value,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        affiliateres: affiliateResource,
      })
    );
  };

  const handleTitleAv = (e) => {
    setTitleAv(e.target.value);
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: e.target.value,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        affiliateres: affiliateResource,
      })
    );
  };
  const handleDescriptionAv = (e) => {
    setDescriptionAv(e.target.value);
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: e.target.value,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        affiliateres: affiliateResource,
      })
    );
  };
  const handleImageAv = (e) => {
    setImageAv(e.target.value);

    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: e.target.value,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        affiliateres: affiliateResource,
      })
    );
  };
  const handleUrlAv = (e) => {
    setUrlAv(e.target.value);

    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: e.target.value,
        showonHomeAvailable: showonHome,
        affiliateres: affiliateResource,
      })
    );
  };

  const handleShowonHome = (e) => {
    setShowonHome(e.target.value);
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: e.target.value,
        affiliateres: affiliateResource,
      })
    );
  };

  const handleAffiliate = (e) => {
    setAffiliateResource(e.target.value);
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        affiliateres: e.target.value,
      })
    );
  };

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const visibleColumns = columns.filter((column) => !hiddenColumns?.includes(column.name));
  const [checkBoxValue, setCheckBoxValue] = useState({
    "Date Registered": true,
    "Customer Details": true,
    "Thumbnail Images": true,
    "Resource Details": true,
    "Resource Type": true,
    "Resource URL": true,
    "Total Searches": true,
    "Total Views": true,
    Description: true,
    Status: true,
    "Show on Home": true,
    Keywords: true,
    "ISBN Number": true,
    "Priority of Resource": true,
    "Affiliate Resource": true,
    Grade: true,
  });

  /*===========update State on CheckBox change ==========*/

  const handleCheckBoxChange = (checkboxname) => {
    setHiddenColumns((prevHiddenColumns) => {
      if (prevHiddenColumns?.includes(checkboxname)) {
        return prevHiddenColumns?.filter((col) => col !== checkboxname);
      } else {
        return [...prevHiddenColumns, checkboxname];
      }
    });

    setCheckBoxValue((prevValues) => ({
      ...prevValues,
      [checkboxname]: !prevValues[checkboxname],
    }));
  };

  /*==============Store State in local Storage===============*/

  useEffect(() => {
    localStorage.setItem("checkboxvalues", JSON.stringify(checkBoxValue));
  }, [checkBoxValue]);

  useEffect(() => {
    localStorage.setItem("hiddencolumn", JSON.stringify(hiddenColumns));
  }, [hiddenColumns]);

  var gethiddenvalue = JSON.parse(localStorage.getItem("hiddencolumn"));
  useEffect(() => {
    setHiddenColumns(gethiddenvalue);
  }, []);
  /*==================get Data from LocalStorage================*/
  var getCheckBoxValue = JSON.parse(localStorage.getItem("checkboxvalues"));
  useEffect(() => {
    setCheckBoxValue(getCheckBoxValue);
  }, []);

  // Get customer API for parent and customer data
  let selectParentId;
  let selectChildId;
  const handleParentList = (e) => {
    selectParentId = e;
    setparentid(e);
    // getChildListData();
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: e,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        customerType: customerType,
        affiliateres: affiliateResource,
      })
    );
  };
  // console.log("parent",parentid)
  const handleChildId = (e) => {
    selectChildId = e.target.value;
    setChildId(e.target.value);
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        // pagenumberresource: currentPage,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: e.target.value,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        customerType: customerType,
        affiliateres: affiliateResource,
      })
    );
  };
  const handlePillerList = (e) => {
    setPillerId(e.target.value);
  };

  const getCustomerParentDatas = () => {
    // setLoader(true);
    makePostRequest(URLS.RESOURCE_USER, null, null, null)
      .then((res) => {
        //  console.log("Response cust",res)
        if (res.code == 200) {
          // setGetCustomerParentData(res.data?.data);
          setGetCustomerParentData((prevOptions) => [...prevOptions, ...res?.data?.data]);
          setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMenuScroll = (event) => {
    const { target } = event;
    if (target.scrollTop === target.scrollHeight - target.clientHeight) {
      getCustomerParentDatas();
    }
  };

  const getChildListData = () => {
    let data = {
      user_id: parentid?.value,
    };
    // console.log(data,"data:::::::")
    makePostRequest(URLS.GETALL_CHILD, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setGetChildlistData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Instruction Style API calling Here...
  const handleViewInstructionStyle = () => {
    makeGetRequest(URLS.GET_ALL_INSTRUCTION_STYLE, {}, null)
      .then((res) => {
        if (res.code == 200) {
          let filterContent = res.data.filter((val) => val.status === "enable");
          setGetInstructionStyleResourcePage(filterContent);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Experience API calling Here...

  const handlViewExperience = () => {
    // setLoader(true);
    makeGetRequest(URLS.GET_ALL_EXPERIENCE_TYPE, {}, null)
      .then((res) => {
        if (res.code === 200) {
          let filterDataExperience = res.data.filter((val) => val.status === "enable");
          setGetAllExperienceResourcePage(filterDataExperience);
          // setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Facilities API calling Here...

  const handlViewFacility = () => {
    // setLoader(true);
    makeGetRequest(URLS.GET_ALL_FACILITY_TYPE, {}, null)
      .then((res) => {
        if (res.code === 200) {
          let FilterFacility = res.data.filter((val) => val.status === "enable");
          setGetAllFacilityResourcePage(FilterFacility);
          // setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Funding API calling Here...
  const handlViewFunding = () => {
    // setLoader(true);
    makeGetRequest(URLS.GET_ALL_FUNDING_TYPE, {}, null)
      .then((res) => {
        if (res.code === 200) {
          let FilterFunding = res.data.filter((val) => val.status === "enable");
          setGetAllFundingResourcePage(FilterFunding);
          // setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //Organization API calling Here...

  const handlViewOrganization = () => {
    // setLoader(true);
    makeGetRequest(URLS.GET_ALL_ORGANISATION_TYPE, {}, null)
      .then((res) => {
        if (res.code === 200) {
          let organisationFilterData = res.data.filter((val) => val.status === "enable");
          setGetAllOrganizationResourcePage(organisationFilterData);
          // setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //Support API calling Here...
  const handlViewSupport = () => {
    // setLoader(true);
    makeGetRequest(URLS.GET_ALL_SUPPORT_TYPE, {}, null)
      .then((res) => {
        if (res.code === 200) {
          let organisationFilterData = res.data.filter((val) => val.status === "enable");
          setGetAllStudentSupportResourcePage(organisationFilterData);
          setGetTeacherParentSupportResourcePage(organisationFilterData);
          // setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isImageValid = (imageUrl) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => {
        resolve(false);
      };
      img.src = imageUrl;
    });
  };

  const getresourcefun = async () => {
    if (resourcedata.learningPillarVal && resourcedata.learningPillarVal.length > 0) {
      resourcedata.learningPillarVal.forEach((learningpillarid) => learning_pillars_arr.push(learningpillarid.value));
    }
    setLoader(true);
    let data = {
      resource_mode: resourcedata.resourceMode ? resourcedata.resourceMode : "",
      user_id: resourcedata.parentId?.value ? resourcedata.parentId?.value : "",
      child_id: resourcedata.childId ? resourcedata.childId : "",
      // search_title: search ? search :"",
      search_title: resourcedata.search ? resourcedata.search?.trim() : "",
      piller_id: learning_pillars_arr ? learning_pillars_arr : "",
      // piller_id:resourcedata.learningPillarVal ? resourcedata.learningPillarVal :"",
      resource_type: resourcedata.resourceType ? resourcedata.resourceType : "",
      content_type: resourcedata.curriculumContentType ? resourcedata.curriculumContentType : "", // Activated
      experience_type: resourcedata.experienceType ? resourcedata.experienceType : "",
      facility_type: resourcedata.facilityType ? resourcedata.facilityType : "",
      funding_type: resourcedata.fundingType ? resourcedata.fundingType : "",
      organization_type: resourcedata.organizationType ? resourcedata.organizationType : "",
      // student_support_type: studentSupportType ? studentSupportType : "",
      // teacher_parrent_support_type: teacherParentSupportType ? teacherParentSupportType : "",
      support_type: resourcedata.studentSupportType
        ? resourcedata.studentSupportType
        : "" || resourcedata.teacherParentSupportType
        ? resourcedata.teacherParentSupportType
        : "",
      // start_date: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      start_date: resourcedata.startDate ? moment(resourcedata.startDate).format("MM-DD-YYYY") : "",
      // end_date: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
      end_date: resourcedata.endDate ? moment(resourcedata.endDate).format("MM-DD-YYYY") : "",
      timeframe: resourcedata.timeframe ? resourcedata.timeframe : "",
      page: resourcedata?.pagenumberresource ? resourcedata?.pagenumberresource : 1,
      limit: resourcedata.limitvalueresource ? resourcedata.limitvalueresource : 10,
      //  searchKey: search ?? "",
      searchKey: "",
      key: "",
      // status: resourcestate ? resourcestate : "",
      status: resourcedata.status ? resourcedata.status : "",
      is_deleted: resourcedata.statusDelete ? resourcedata.statusDelete : "",
      // sortby: sortresource ? sortresource : "",
      sortby: resourcedata.sortvalueresource ? resourcedata.sortvalueresource : "",
      is_review: resourcedata.resourceReview ? resourcedata.resourceReview : "",
      resource_title: resourcedata.titleAvailable ? resourcedata.titleAvailable : "",
      resource_thumbnail_image: resourcedata.imgAvailable ? resourcedata.imgAvailable : "",
      resource_url: resourcedata.urlAvailable ? resourcedata.urlAvailable : "",
      isbn: resourcedata.isbnSearch ? resourcedata.isbnSearch : "",
      description: resourcedata.descriptionAvailable ? resourcedata.descriptionAvailable : "",
      showonhomepage: resourcedata.showonHomeAvailable ? resourcedata.showonHomeAvailable : "",
      instructional_style: resourcedata.instructionalStyleType ? resourcedata.instructionalStyleType : "",
      customer_type: resourcedata.customerType ? resourcedata.customerType : "",
      affiliate_resource: resourcedata.affiliateres ? resourcedata.affiliateres : "",
    };
    // console.log(data, "data.....");

    try {
      const res = await makePostRequest(URLS.GET_ALL_RESOURCES, null, data, null);
      //  console.log("All resource", res);
      if (res?.code === 200) {
        const processedData = await Promise.all(
          res?.data?.data.map(async (row) => {
            const imageUrl = row.imageLink;
            try {
              const isValid = await isImageValid(imageUrl);
              return isValid ? row : { ...row, imageLink: null };
            } catch (error) {
              console.error("Error checking image validity:", error.message);
              return { ...row, imageLink: null };
            }
          })
        );
        // console.log("Processed Data: ", processedData);
        setGetAllResources(processedData);
        setCount(Math.ceil(res?.data?.count / (resourcedata.limitvalueresource || 10)));
      } else {
        setGetAllResources([]);
      }

      setLoader(false);
    } catch (error) {
      // console.error("Error in getresourcefun: ", error);
      setLoader(false);
    }
  };

  // const getresourcefun = async () => {
  //   if (resourcedata.learningPillarVal && resourcedata.learningPillarVal.length > 0) {
  //     resourcedata.learningPillarVal.forEach((learningpillarid) =>
  //       learning_pillars_arr.push(learningpillarid.value)
  //     );
  //   }

  //   setLoader(true);

  //   let data = {
  //     resource_mode: resourcedata.resourceMode || "",
  //     user_id: resourcedata.parentId?.value || "",
  //     child_id: resourcedata.childId || "",
  //     search_title: resourcedata.search?.trim() || "",
  //     piller_id: learning_pillars_arr || "",
  //     resource_type: resourcedata.resourceType || "",
  //     content_type: resourcedata.curriculumContentType || "",
  //     experience_type: resourcedata.experienceType || "",
  //     facility_type: resourcedata.facilityType || "",
  //     funding_type: resourcedata.fundingType || "",
  //     organization_type: resourcedata.organizationType || "",
  //     support_type: resourcedata.studentSupportType || resourcedata.teacherParentSupportType || "",
  //     start_date: resourcedata.startDate ? moment(resourcedata.startDate).format("MM-DD-YYYY") : "",
  //     end_date: resourcedata.endDate ? moment(resourcedata.endDate).format("MM-DD-YYYY") : "",
  //     timeframe: resourcedata.timeframe || "",
  //     page: resourcedata.pagenumberresource || 1,
  //     limit: resourcedata.limitvalueresource || 10,
  //     searchKey: "",
  //     key: "",
  //     status: resourcedata.status || "",
  //     is_deleted: resourcedata.statusDelete || "",
  //     sortby: resourcedata.sortvalueresource || "",
  //     is_review: resourcedata.resourceReview || "",
  //     resource_title: resourcedata.titleAvailable || "",
  //     resource_thumbnail_image: resourcedata.imgAvailable || "",
  //     resource_url: resourcedata.urlAvailable || "",
  //     isbn: resourcedata.isbnSearch || "",
  //     description: resourcedata.descriptionAvailable || "",
  //     showonhomepage: resourcedata.showonHomeAvailable || "",
  //     instructional_style: resourcedata.instructionalStyleType || "",
  //     customer_type: resourcedata.customerType || "",
  //     affiliate_resource: resourcedata.affiliateres || "",
  //   };

  //   console.log("Request data: ", data);

  //   try {
  //     const res = await makePostRequest(URLS.GET_ALL_RESOURCES, null, data, null);
  //     console.log("API Response: ", res);

  //     if (res?.code === 200) {
  //       const processedData = await Promise.all(
  //         res?.data?.data.map(async (row) => {
  //           const imageUrl = row.imageLink;
  //           try {
  //             const isValid = await isImageValid(imageUrl);
  //             return isValid ? row : { ...row, imageLink: null };
  //           } catch (error) {
  //             console.error("Error checking image validity:", error.message);
  //             return { ...row, imageLink: null };
  //           }
  //         })
  //       );

  //       console.log("Processed Data: ", processedData);

  //       setGetAllResources(processedData);
  //       setCount(Math.ceil(res?.data?.count / (resourcedata.limitvalueresource || 10)));
  //     } else {
  //       setGetAllResources([]);
  //     }

  //     setLoader(false);
  //   } catch (error) {
  //     console.error("Error in getresourcefun: ", error);
  //     setLoader(false);
  //   }
  // };

  const handleSearch = () => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("Please select valid end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("Please select valid start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("End date should be greater than start date");
    } else {
      //  setCount(1)
      // resourcedata.pagenumberresource="";
      setCurrentPage(1);
      getresourcefun();
    }
  };
  const getallLearningPiller = () => {
    makeGetRequest(URLS.GET_ALL_LEARNING_PILLER, {}, null)
      .then((res) => {
        if (res.code === 200) {
          setAllLearningPiller(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getLearningPillarsData = () => {
    makeGetRequest(URLS.GET_ALL_LEARNING_PILLER, null, null, null)
      .then((res) => {
        // console.log(res, "lres");
        if (res.code === 200) {
          let options = [];
          res.data.length > 0 &&
            res.data.map((val, i) => {
              options.push({ value: val.id, label: val.name });
            });
          setLearningPillarData(options);
          // setLearningPillarData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const clearfilter = () => {
    let resourcedata = JSON.parse(sessionStorage.getItem("resource")) || {};

    resourcedata.resourceMode = "";
    resourcedata.limitvalueresource = "";
    resourcedata.sortvalueresource = "";
    resourcedata.parentId = "";
    resourcedata.childId = "";
    resourcedata.pagenumberresource = "";
    resourcedata.resourceType = "";
    resourcedata.curriculumContentType = "";
    resourcedata.experienceType = "";
    resourcedata.facilityType = "";
    resourcedata.fundingType = "";
    resourcedata.organizationType = "";
    resourcedata.studentSupportType = "";
    resourcedata.teacherParentSupportType = "";
    resourcedata.timeframe = "";
    resourcedata.instructionalStyleType = "";
    resourcedata.learningPillarVal = "";
    resourcedata.startDate = "";
    resourcedata.endDate = "";
    resourcedata.search = "";
    resourcedata.isbnSearch = "";
    resourcedata.status = "";
    resourcedata.statusDelete = "";
    resourcedata.resourceReview = "";
    resourcedata.titleAvailable = "";
    resourcedata.descriptionAvailable = "";
    resourcedata.imgAvailable = "";
    resourcedata.urlAvailable = "";
    resourcedata.showonHomeAvailable = "";
    resourcedata.customerType = "";
    resourcedata.affiliateres = "";

    sessionStorage.setItem("resource", JSON.stringify(resourcedata));

    setCurrentPage(1);
    setCustomerType("");
    setInstructionalStyleType("");
    setparentid("");
    setSaveServe("");
    setChildId("");
    setResourceType("");
    setCurriculamContentType("");
    setExperienceType("");
    setFacilityType("");
    setFundingType("");
    setOrganizationType("");
    setStudentSupportType("");
    setTeacherParentSupportType("");
    setTimeFrameTypeCariculam("");
    setTimeFrameTypeExperience("");
    setResourceTypeText("");
    setSearch("");
    setPillerId("");
    setSelectedfromDate("");
    setSelectedToDate("");
    setSearchByISBN("");
    setresourcestate("");
    setresourcedeletestate("");
    setReviewdresource("");
    setShowonHome("");
    setUrlAv("");
    setImageAv("");
    setAffiliateResource("");
    setDescriptionAv("");
    setTitleAv("");
    setLearningPillarIds("");
    setSortResource("");
    setlimitvalue("");
    setClearState(!clearState);
  };

  useEffect(() => {
    // if(clearState == true){
    getresourcefun();
    // console.log("clear state")
    // }
  }, [clearState]);

  const handleButtonClickClear = () => {
    clearfilter();
  };

  const [contentTypeData, setcontentTypeData] = useState([]);
  //api call to get all content type data
  const getAllContentTypes = () => {
    makeGetRequest(URLS.GET_ALL_CONTENT_TYPE, null, null, null)
      .then((res) => {
        // console.log(res, "content");
        if (res.code === 200) {
          let filterstatus = res.data.filter((val) => val.status === "enable");
          setcontentTypeData(filterstatus);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllUserType();
    getCustomerParentDatas();
    getLearningPillarsData();
    handlViewFacility();
    handlViewFunding();
    handlViewOrganization();
    handlViewExperience();
    handlViewSupport();
    handleViewInstructionStyle();
    getAllContentTypes();
  }, []);

  useEffect(() => {
    getChildListData();
  }, [parentid?.value]);

  const ExportEcelFile = () => {
    if (resourcedata.learningPillarVal && resourcedata.learningPillarVal.length > 0) {
      resourcedata.learningPillarVal.forEach((learningpillarid) => learning_pillars_arr.push(learningpillarid.value));
    }
    setLoader(true);
    let data = {
      resource_mode: resourcedata.resourceMode ? resourcedata.resourceMode : "",
      user_id: resourcedata.parentId?.value ? resourcedata.parentId?.value : "",
      child_id: resourcedata.childId ? resourcedata.childId : "",
      // search_title: search ? search :"",
      search_title: resourcedata.search ? resourcedata.search?.trim() : "",
      piller_id: learning_pillars_arr ? learning_pillars_arr : "",
      // piller_id:resourcedata.learningPillarVal ? resourcedata.learningPillarVal :"",
      resource_type: resourcedata.resourceType ? resourcedata.resourceType : "",
      content_type: resourcedata.curriculumContentType ? resourcedata.curriculumContentType : "", // Activated
      experience_type: resourcedata.experienceType ? resourcedata.experienceType : "",
      facility_type: resourcedata.facilityType ? resourcedata.facilityType : "",
      funding_type: resourcedata.fundingType ? resourcedata.fundingType : "",
      organization_type: resourcedata.organizationType ? resourcedata.organizationType : "",
      // student_support_type: studentSupportType ? studentSupportType : "",
      // teacher_parrent_support_type: teacherParentSupportType ? teacherParentSupportType : "",
      support_type: resourcedata.studentSupportType
        ? resourcedata.studentSupportType
        : "" || resourcedata.teacherParentSupportType
        ? resourcedata.teacherParentSupportType
        : "",
      // start_date: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      start_date: resourcedata.startDate ? moment(resourcedata.startDate).format("MM-DD-YYYY") : "",
      // end_date: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
      end_date: resourcedata.endDate ? moment(resourcedata.endDate).format("MM-DD-YYYY") : "",
      timeframe: resourcedata.timeframe ? resourcedata.timeframe : "",
      page: resourcedata?.pagenumberresource ? resourcedata?.pagenumberresource : 1,
      limit: resourcedata.limitvalueresource ? resourcedata.limitvalueresource : 10,
      //  searchKey: search ?? "",
      searchKey: "",
      key: "export",
      // status: resourcestate ? resourcestate : "",
      status: resourcedata.status ? resourcedata.status : "",
      is_deleted: resourcedata.statusDelete ? resourcedata.statusDelete : "",
      // sortby: sortresource ? sortresource : "",
      sortby: resourcedata.sortvalueresource ? resourcedata.sortvalueresource : "",
      is_review: resourcedata.resourceReview ? resourcedata.resourceReview : "",
      resource_title: resourcedata.titleAvailable ? resourcedata.titleAvailable : "",
      resource_thumbnail_image: resourcedata.imgAvailable ? resourcedata.imgAvailable : "",
      resource_url: resourcedata.urlAvailable ? resourcedata.urlAvailable : "",
      isbn: resourcedata.isbnSearch ? resourcedata.isbnSearch : "",
      description: resourcedata.descriptionAvailable ? resourcedata.descriptionAvailable : "",
      showonhomepage: resourcedata.showonHomeAvailable ? resourcedata.showonHomeAvailable : "",
      instructional_style: resourcedata.instructionalStyleType ? resourcedata.instructionalStyleType : "",
      customer_type: resourcedata.customerType ? resourcedata.customerType : "",
      affiliate_resource: resourcedata.affiliateres ? resourcedata.affiliateres : "",
    };
    // console.log(data, "export payload");
    makePostRequest(URLS.GET_ALL_RESOURCES_EXPORT, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          //  console.log("resource export",res)
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("ResourceData");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "Date Registered",
              key: "REGISTER_DATE",
              width: 15,
            },
            {
              header: "CUSTOMER",
              key: "CUSTOMER",
              width: 15,
            },
            {
              header: "EMAIL",
              key: "EMAIL",
              width: 15,
            },
            {
              header: "PHONE",
              key: "PHONE",
              width: 15,
            },
            {
              header: "RESOURCE TYPE",
              key: "RESOURCE_TYPE",
              width: 18,
            },
            {
              header: "TITLE",
              key: "resource_title",
              width: 18,
            },
            {
              header: "RESOURCE MODE",
              key: "resource_mode",
              width: 18,
            },
            {
              header: "Learning Pillar",
              key: "Learning_pillar",
              width: 18,
            },
            {
              header: "Start Date",
              key: "Start_Date",
              width: 18,
            },
            {
              header: "End Date",
              key: "End_Date",
              width: 18,
            },
            {
              header: "Start Time",
              key: "Start_Time",
              width: 18,
            },
            {
              header: "End Time",
              key: "End_Time",
              width: 18,
            },
            {
              header: "Category",
              key: "Category",
              width: 18,
            },
            {
              header: "Instruction Style",
              key: "Instruction_Style",
              width: 18,
            },
            {
              header: "Timeframe",
              key: "Timeframe",
              width: 18,
            },
            {
              header: "Accrediated Resource",
              key: "Accrediated_Resource",
              width: 18,
            },
            {
              header: "Accrediated Institute",
              key: "Accrediated_Institute",
              width: 18,
            },
            {
              header: "Dual Enrollment",
              key: "Dual_Enrollmente",
              width: 18,
            },
            {
              header: "Credit Granting Institution",
              key: "Credit_Granting_Institution",
              width: 18,
            },
            {
              header: "College Credits",
              key: "College_Credits",
              width: 18,
            },
            {
              header: "Resource URL",
              key: "Resource_URL",
              width: 18,
            },
            {
              header: "ISBN",
              key: "ISBN",
              width: 18,
            },
            {
              header: "Description",
              key: "Description",
              width: 18,
            },
            {
              header: "About Resource",
              key: "About_Resource",
              width: 18,
            },
            {
              header: "Grade Range",
              key: "Grade_Range",
              width: 18,
            },
            {
              header: "Save For",
              key: "Save_For",
              width: 18,
            },
            {
              header: "Share With",
              key: "Share_With",
              width: 18,
            },
            {
              header: "Location Address",
              key: "Location_Address",
              width: 18,
            },
            {
              header: "Keywords",
              key: "Keywords",
              width: 18,
            },
            {
              header: "Elgibile for public funding",
              key: "Elgibile_for_public_funding",
              width: 18,
            },
            {
              header: "Funding States",
              key: "Funding_States",
              width: 18,
            },
            {
              header: "($)Price",
              key: "Price",
              width: 18,
            },
            {
              header: "Type of Experience",
              key: "Type_of_Experience",
              width: 18,
            },
            {
              header: "Type of facility",
              key: "Type_of_facility",
              width: 18,
            },
            {
              header: "Type of Funding",
              key: "Type_of_Funding",
              width: 18,
            },
            {
              header: "Type of Organization",
              key: "Type_of_Organization",
              width: 18,
            },
            {
              header: "Type of Support",
              key: "Type_of_Support",
              width: 18,
            },
            {
              header: "Type of Content",
              key: "Type_of_Content",
              width: 18,
            },
            {
              header: "Customer type",
              key: "Customer_type",
              width: 18,
            },
            {
              header: "Location",
              key: "Location",
              width: 18,
            },

            {
              header: "Total Views",
              key: "Total_views",
              width: 18,
            },
            {
              header: "Total Rating",
              key: "Total_Rating",
              width: 18,
            },
            {
              header: "Total Comments",
              key: "Total_Comments",
              width: 18,
            },
            {
              header: "Total Likes",
              key: "Total_Likes",
              width: 18,
            },
            {
              header: "Total Shared",
              key: "Total_Shared",
              width: 18,
            },
            {
              header: "Total Purchased",
              key: "Total_Purchased",
              width: 18,
            },
            {
              header: "Total Saved",
              key: "Total_Saved",
              width: 18,
            },
            {
              header: "Total Sessions",
              key: "Total_Sessions",
              width: 18,
            },
            {
              header: "STATUS",
              key: "STATUS",
              width: 15,
            },
            {
              header: "Show on Home",
              key: "Show_on_Home_Page",
              width: 18,
            },
            {
              header: "Review by Admin",
              key: "Reviwed_by_Admin",
              width: 18,
            },
            {
              header: "Viewable by Friend",
              key: "Viewable_by_Friend",
              width: 18,
            },
            {
              header: "Priority of Resource",
              key: "Priority_of_resource",
              width: 18,
            },
          ];

          res?.data?.data?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,
              REGISTER_DATE: moment(val?.created_at).format("MM-DD-YYYY"),
              CUSTOMER: val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName,
              EMAIL: val?.tbl_user?.email ? val.tbl_user.email : "--",
              PHONE: val?.tbl_user?.phone ? val.tbl_user.phone : "--",
              RESOURCE_TYPE: val?.tbl_resource_type_master?.name ? val.tbl_resource_type_master.name : "--",
              resource_title: val?.resource_title ? val.resource_title : "--",
              resource_mode: val?.resource_mode ? val.resource_mode : "--",
              Learning_pillar:
                val?.tbl_pillar_resource_mappings && val?.tbl_pillar_resource_mappings.length > 0
                  ? val?.tbl_pillar_resource_mappings.map((item) => item?.tbl_learning_pillar_master?.name).join(", ")
                  : "--",
              Start_Date: val?.start_date ? val.start_date : "--",
              End_Date: val?.end_date ? val.end_date : "--",
              Start_Time: val?.start_time ? val.start_time : "--",
              End_Time: val?.end_time ? val.end_time : "--",
              Category: val?.storing_type ? val.storing_type : "--",
              Instruction_Style: val?.tbl_resource_instruction_styles[0]?.tbl_instructional_style?.name
                ? val?.tbl_resource_instruction_styles[0]?.tbl_instructional_style?.name
                : "--",
              Timeframe: val?.tbl_resource_timeframes[0]?.timeframe ? val?.tbl_resource_timeframes[0]?.timeframe : "--",
              Accrediated_Resource: val?.accredited_resource ? val.accredited_resource : "--",
              Accrediated_Institute: val?.accrediting_institution ? val.accrediting_institution : "--",
              Dual_Enrollmente: val?.dual_enrollment_resource ? val.dual_enrollment_resource : "--",
              Credit_Granting_Institution: val?.credit_granting_institution ? val.credit_granting_institution : "--",
              College_Credits: val?.college_credits_number ? val?.college_credits_number : "--",
              Credit_Granting_Organization: val?.tbl_credit_granting_organization?.name
                ? val.tbl_credit_granting_organization.name
                : "--",
              Resource_URL: val?.resource_url ? val.resource_url : "--",
              ISBN: val?.isbn ? val.isbn : "--",
              Save_For: val?.save_for ? val.save_for : "--",
              Location_Address: val?.define_address ? val.define_address : "--",
              Keywords:
                val?.tbl_resource_keywords && val?.tbl_resource_keywords?.length > 0
                  ? val?.tbl_resource_keywords.map((item) => item.keyword).join(", ")
                  : "--",
              Elgibile_for_public_funding: val?.public_funding ? val.public_funding : "--",
              Funding_States:
                val?.tbl_resource_funding_states && val?.tbl_resource_funding_states?.length > 0
                  ? val?.tbl_resource_funding_states?.map((item) => item.tbl_state?.name).join(", ")
                  : "--",
              Price: val?.purchase_price ? val.purchase_price : "--",
              Type_of_Experience:
                val?.tbl_resource_experiences && val?.tbl_resource_experiences?.length > 0
                  ? val?.tbl_resource_experiences?.map((item) => item.tbl_type_experience_master?.name).join(", ")
                  : "--",
              Type_of_Funding:
                val?.tbl_resource_fundings && val?.tbl_resource_fundings?.length > 0
                  ? val?.tbl_resource_fundings?.map((item) => item.tbl_type_funding_master?.name).join(", ")
                  : "--",
              Type_of_Organization:
                val?.tbl_resource_organisations && val?.tbl_resource_organisations?.length > 0
                  ? val?.tbl_resource_organisations?.map((item) => item.tbl_type_organisation_master?.name).join(", ")
                  : "--",
              Type_of_Support:
                val?.tbl_resource_supports && val?.tbl_resource_supports?.length > 0
                  ? val?.tbl_resource_supports?.map((item) => item.tbl_type_support_master?.name).join(", ")
                  : "--",
              Type_of_Content: val?.tbl_resource_contents[0]?.tbl_type_content_master?.name
                ? val?.tbl_resource_contents[0]?.tbl_type_content_master?.name
                : "--",
              Show_on_Home_Page: val?.show_on_home_page ? val.show_on_home_page : "--",
              Reviwed_by_Admin: val?.is_review ? val.is_review : "--",
              Viewable_by_Friend: val?.viewable_by_my_friends ? val.viewable_by_my_friends : "--",
              Credit_Granting_Organization: val?.tbl_credit_granting_organization?.name
                ? val.tbl_credit_granting_organization.name
                : "--",
              Resource_URL: val?.resource_url ? val.resource_url : "--",
              ISBN: val?.isbn ? val.isbn : "--",
              Description: val?.description ? val.description.replace(/(<([^>]+)>)/gi, "") : "--",
              About_Resource: val?.about_resource ? val.about_resource.replace(/(<([^>]+)>)/gi, "") : "--",
              Grade_Range:
                val?.tbl_grade_resource_mappings && val?.tbl_grade_resource_mappings?.length > 0
                  ? val?.tbl_grade_resource_mappings.map((temp) => temp.tbl_grade_level.name).join(", ")
                  : "--",
              Save_For: val?.save_for ? val.save_for : "--",
              Location:
                val?.tbl_resource_location_types && val?.tbl_resource_location_types?.length > 0
                  ? val?.tbl_resource_location_types?.map((item) => item.location_type).join(", ")
                  : "--",
              Keywords:
                val?.tbl_resource_keywords && val?.tbl_resource_keywords?.length > 0
                  ? val?.tbl_resource_keywords.map((item) => item.keyword).join(", ")
                  : "--",
              Elgibile_for_public_funding: val?.public_funding ? val.public_funding : "--",

              Funding_States:
                val?.tbl_resource_funding_states && val?.tbl_resource_funding_states?.length > 0
                  ? val?.tbl_resource_funding_states?.map((item) => item.tbl_state?.name).join(", ")
                  : "--",
              Price: val?.purchase_price ? val.purchase_price : "--",
              Type_of_Experience:
                val?.tbl_resource_experiences && val?.tbl_resource_experiences?.length > 0
                  ? val?.tbl_resource_experiences?.map((item) => item.tbl_type_experience_master?.name).join(", ")
                  : "--",
              Type_of_facility:
                val?.tbl_resource_facilities && val?.tbl_resource_facilities?.length > 0
                  ? val?.tbl_resource_facilities?.map((item) => item.tbl_type_facility_master?.name).join(", ")
                  : "--",
              Customer_type: val?.tbl_resource_customer_types[0]?.customer_type
                ? val.tbl_resource_customer_types[0]?.customer_type
                : "--",
              Total_views: val?.total_time_viewed ? val.total_time_viewed : "0",
              Total_Rating: val?.rating ? val.rating : "0",
              Total_Comments: val?.total_comments ? val.total_comments : "0",
              Total_Likes: val?.total_likes ? val.total_likes : "0",
              Total_Shared: val?.total_shared ? val.total_shared : "0",
              Total_Saved: val?.tbl_resource_saveds.length ? val.tbl_resource_saveds.length : "0",
              Total_Purchased: val?.tbl_resource_purchases ? val.tbl_resource_purchases.length : "0",
              Total_Sessions: val?.tbl_user_mentoring_sessions ? val.tbl_user_mentoring_sessions.length : "0",
              STATUS: val?.status ? val.status : "--",
              Show_on_Home_Page: val?.show_on_home_page ? val.show_on_home_page : "--",
              Viewable_by_Friend: val?.viewable_by_my_friends ? val.viewable_by_my_friends : "--",
              Priority_of_resource: val?.priority_of_resource ? val?.priority_of_resource : "--",
            });
          });

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "Resource.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Handle page change event
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    sessionStorage.setItem(
      "resource",
      JSON.stringify({
        resourceMode: saveserve,
        limitvalueresource: limitvalue,
        pagenumberresource: selectedPage.selected + 1,
        sortvalueresource: sortresource,
        parentId: parentid,
        childId: childId,
        resourceType: resourceType,
        curriculumContentType: curriculamContentType,
        experienceType: experienceType,
        facilityType: facilityType,
        fundingType: fundingType,
        organizationType: organizationType,
        studentSupportType: studentSupportType,
        teacherParentSupportType: teacherParentSupportType,
        timeframe: timeFrameTypeCariculam,
        instructionalStyleType: instructionalStyleType,
        learningPillarVal: learningPillarIds,
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        search: search,
        isbnSearch: searchByISBN,
        status: resourcestate,
        statusDelete: resourcedeletestate,
        resourceReview: reviewdresource,
        titleAvailable: titleAv,
        descriptionAvailable: descriptionAv,
        imgAvailable: imageAv,
        urlAvailable: urlAv,
        showonHomeAvailable: showonHome,
        customerType: customerType,
        affiliateres: affiliateResource,
      })
    );
  };

  // Apply code for click outside and close popup
  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const selectRefFilter = useRef(null);
  const selectRefresource = useRef(null);

  const handleParentCustomer = () => {
    if (selectRefresource.current) {
      setDisplayvalueParentCustomer(!displayvalueParentCustomer);
      selectRefresource.current.focus();
    }
  };

  const displaySelectFilter = (e) => {
    if (selectRefFilter.current) {
      setDisplayValueSelectFilter(!displayvalueSelectFilter);
      selectRefFilter.current.focus();
    }
  };

  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };
  const handleOutSideClick1 = (events) => {
    if (newRef1.current && !newRef1.current.contains(events.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  });

  return (
    <React.Fragment>
      <Head title="Resources List"></Head>
      <ToastContainer />
      {/* <h3 className="nk-block-title page-title mb5 pb-4"> Resource List </h3> */}

      <Content>
        <h3 className="nk-block-title page-title mt-3 pb-4 pt-5"> Resource List </h3>
        {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square">
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : (
          <Card className="card-bordered">
            <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Columns Setting
              </ModalHeader>
              <Card className="card-bordered">
                <div className="space-used-details">
                  <div className="card-inner">
                    <BlockHeadContent>
                      <BlockHead size="lg">
                        <div className="customerDetailsTable">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              // value=""
                              id="flexCheckDefault"
                              checked={checkBoxValue?.["Date Registered"]}
                              onChange={() => handleCheckBoxChange("Date Registered")}
                            />
                            <label className="form-check-label" for="flexCheckDefault">
                              Date Registered
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault1"
                              checked={checkBoxValue?.["Customer Details"]}
                              onChange={() => handleCheckBoxChange("Customer Details")}
                            />
                            <label className="form-check-label" for="flexCheckDefault1">
                              Customer Details
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault2"
                              checked={checkBoxValue?.["Thumbnail Images"]}
                              onChange={() => handleCheckBoxChange("Thumbnail Images")}
                            />
                            <label className="form-check-label" for="flexCheckDefault2">
                              Thumbnail Images
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault3"
                              checked={checkBoxValue?.["Resource Details"]}
                              onChange={() => handleCheckBoxChange("Resource Details")}
                            />
                            <label className="form-check-label" for="flexCheckDefault3">
                              Resource Details
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault4"
                              checked={checkBoxValue?.["Resource Type"]}
                              onChange={() => handleCheckBoxChange("Resource Type")}
                            />
                            <label className="form-check-label" for="flexCheckDefault4">
                              Resource Type
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault5"
                              checked={checkBoxValue?.["Resource URL"]}
                              onChange={() => handleCheckBoxChange("Resource URL")}
                            />
                            <label className="form-check-label" for="flexCheckDefault5">
                              Resource URL
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault6"
                              checked={checkBoxValue?.["Total Searches"]}
                              onChange={() => handleCheckBoxChange("Total Searches")}
                            />
                            <label className="form-check-label" for="flexCheckDefault6">
                              Total Searches
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault7"
                              checked={checkBoxValue?.["Total Views"]}
                              onChange={() => handleCheckBoxChange("Total Views")}
                            />
                            <label className="form-check-label" for="flexCheckDefault7">
                              Total Views
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault8"
                              checked={checkBoxValue?.["Description"]}
                              onChange={() => handleCheckBoxChange("Description")}
                            />
                            <label className="form-check-label" for="flexCheckDefault8">
                              Description
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault9"
                              checked={checkBoxValue?.["Status"]}
                              onChange={() => handleCheckBoxChange("Status")}
                            />
                            <label className="form-check-label" for="flexCheckDefault9">
                              Status
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault10"
                              checked={checkBoxValue?.["Show on Home"]}
                              onChange={() => handleCheckBoxChange("Show on Home")}
                            />
                            <label className="form-check-label" for="flexCheckDefault10">
                              Show on Home
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault11"
                              checked={checkBoxValue?.["Keywords"]}
                              onChange={() => handleCheckBoxChange("Keywords")}
                            />
                            <label className="form-check-label" for="flexCheckDefault11">
                              Keywords
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault123"
                              checked={checkBoxValue?.["Grade"]}
                              onChange={() => handleCheckBoxChange("Grade")}
                            />
                            <label className="form-check-label" for="flexCheckDefault123">
                              Grade
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault12"
                              checked={checkBoxValue?.["ISBN Number"]}
                              onChange={() => handleCheckBoxChange("ISBN Number")}
                            />
                            <label className="form-check-label" for="flexCheckDefault12">
                              ISBN Number
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault13"
                              checked={checkBoxValue?.["Priority of Resource"]}
                              onChange={() => handleCheckBoxChange("Priority of Resource")}
                            />
                            <label className="form-check-label" for="flexCheckDefault13">
                              Priority of Resource
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault14"
                              checked={checkBoxValue?.["Affiliate Resource"]}
                              onChange={() => handleCheckBoxChange("Affiliate Resource")}
                            />
                            <label className="form-check-label" for="flexCheckDefault14">
                              Affiliate Resource
                            </label>
                          </div>
                        </div>
                      </BlockHead>
                    </BlockHeadContent>
                  </div>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>

            <Modal isOpen={open} toggle={handleOpen}>
              <ModalHeader
                className="border-0"
                toggle={handleOpen}
                close={
                  <button className="close" onClick={handleOpen}>
                    <Icon name="cross" />
                  </button>
                }
              ></ModalHeader>
              <Card className="card-bordered border-0">
                <div className="space-used-details">
                  <div className="">
                    <BlockHeadContent>
                      <BlockHead size="lg">
                        <div className="customerDetailsTable">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="text-center"
                            style={{ color: "#2D3134", fontSize: "22px", lineHeight: "36px" }}
                          >
                            Are you sure you want to delete this resource?
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 3 }}
                            className="d-flex justify-content-center gap-4"
                          >
                            <button className="child-delete" onClick={handleDelete}>
                              Yes
                            </button>{" "}
                            <button className="child-cancel" onClick={handleClose}>
                              Cancel
                            </button>
                          </Typography>
                        </div>
                      </BlockHead>
                    </BlockHeadContent>
                  </div>
                </div>
              </Card>
              <ModalFooter className="border-0 p-4"></ModalFooter>
            </Modal>

            <div className="card-inner relativeClass">
              <div className="row pb-2 pt-2  align-items-center">
                <div className="col-lg-4 ">
                  <div className="filtersidebutton">
                    <h5 style={{ cursor: "pointer" }} onClick={ShowHideSelect}>
                      Filters
                      <FaFilter />
                    </h5>
                    <button className="btn btn-md btn-primary" type="submit" onClick={handleButtonClickClear}>
                      Clear Filters
                    </button>
                  </div>
                </div>

                <div className="col-lg-8 d-flex  justify-content-end">
                  <select
                    value={sortresource}
                    className="form-select"
                    style={{ width: "170px" }}
                    onChange={HandleSortResource}
                  >
                    <option value="">Sort By</option>
                    <option value="ASCDATE">Date Ascending</option>
                    <option value="DESCDATE">Date Descending</option>
                    <option value="ASCTITLE">Title Ascending</option>
                    <option value="DESCTITLE">Title Descending</option>
                    <option value="ASCSEARCH">Total Search Count Ascending</option>
                    <option value="DESCSEARCH">Total Search Count Descending</option>
                    <option value="ASCVIEWED">Total View Count Ascending</option>
                    <option value="DESCVIEWED">Total View Count Descending</option>
                    <option value="ASCPRIORITY">Priority Ascending</option>
                    <option value="DESCPRIORITY">Priority Descending</option>
                  </select>

                  <div>
                    <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                  </div>
                  <div>
                    <select
                      value={limitvalue}
                      className="form-select"
                      style={{ width: "85px" }}
                      onChange={HandleLimitValue}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div>
                    &nbsp; &nbsp;
                    <button className="btn btn-primary " onClick={ExportEcelFile}>
                      <em className="icon ni ni-download-cloud"></em>
                      <span>Export</span>
                    </button>
                  </div>
                  <div>
                    &nbsp; &nbsp;
                    <button className="btn btn-primary " onClick={HandleColumn}>
                      <span>Columns Setting</span>
                    </button>
                  </div>
                </div>
              </div>
              <hr></hr>
              <>
                {/* <h4 className="cursorPointer" onClick={ShowHideSelect}>Filters <i className="fa fa-filter" aria-hidden="true"></i> </h4> */}
                {show && (
                  <>
                    <div className="row pb-2">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Resource Mode</strong>
                          </label>
                          <select value={saveserve} className="form-select" onChange={handleSaveServe}>
                            <option value="">Select Mode</option>
                            <option value="save">Save</option>
                            <option value="serve">Serve</option>
                          </select>
                        </div>
                      </div>

                      {saveserve == "" || saveserve == "serve" ? (
                        <div className="col-lg-4">
                          <label>
                            <strong>Parent/Customer</strong>
                          </label>
                          <div className="form-control-wrap paymetsession" style={{ marginBottom: "10px" }}>
                            <i
                              className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                              onClick={handleParentCustomer}
                            ></i>
                            <Select
                              className="form-select form-selectparent"
                              defaultValue={parentid}
                              ref={selectRefresource}
                              openMenuOnFocus={displayvalueParentCustomer}
                              onChange={handleParentList}
                              // onMenuScrollToBottom={handleMenuScroll}
                              // options={resourceDropdown?.map((v) => ({ value: v?.resource_id, label: v?.tbl_user_resource?.resource_title }))}
                              options={[
                                { value: "", label: "All" },
                                ...getCustomerParentData?.map((val) => ({
                                  value: val?.tbl_user?.id,
                                  label: val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName,
                                })),
                              ]}
                            ></Select>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="col-lg-4">
                            <label>
                              <strong>Parent/Customer</strong>
                            </label>
                            <div className="form-control-wrap paymetsession" style={{ marginBottom: "10px" }}>
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={handleParentCustomer}></i>
                              <Select
                                className="form-select form-selectparent"
                                defaultValue={parentid}
                                ref={selectRefresource}
                                openMenuOnFocus={displayvalueParentCustomer}
                                onChange={handleParentList}
                                // onMenuScrollToBottom={handleMenuScroll}
                                // options={resourceDropdown?.map((v) => ({ value: v?.resource_id, label: v?.tbl_user_resource?.resource_title }))}
                                options={[
                                  { value: "", label: "All" },
                                  ...getCustomerParentData?.map((val) => ({
                                    value: val?.tbl_user?.id,
                                    label: val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName,
                                  })),
                                ]}
                              ></Select>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>Student</strong>
                              </label>
                              <select value={childId} className="form-select" onChange={handleChildId}>
                                <option value="">Student List</option>
                                {getChildlistData &&
                                  getChildlistData.map((val, index) => {
                                    return (
                                      <>
                                        <option value={val.id}>
                                          {val.firstName} {"  "} {val.lastName}
                                        </option>
                                      </>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row pb-2">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label>
                            <strong>Type of Resource</strong>
                          </label>
                          <select
                            className="form-select"
                            name="resourceType"
                            onChange={handleResourceType}
                            value={resourceType}
                          >
                            <option value=""> Select Resource </option>
                            {getAllResourceNew.map((val, index) => {
                              return (
                                <>
                                  <option value={val.id} key={index + 1}>
                                    {val.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      {resourceType === "2" && (
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label>
                              {" "}
                              <strong>Type of Content</strong>
                            </label>
                            <select
                              className="form-select"
                              name="curriculamContentType"
                              onChange={handleResourceType}
                              value={curriculamContentType}
                            >
                              <option value="">Select Content Type </option>

                              {contentTypeData &&
                                contentTypeData.map((val) => {
                                  return (
                                    <>
                                      <option value={val.id}> {val.name} </option>
                                    </>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      )}

                      {resourceType === "3" && (
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label>
                              {" "}
                              <strong>Type of Experience</strong>
                            </label>
                            <ul className="px-2 py-2" style={{ display: "none" }}>
                              <li>Type of Experience</li>
                              <li>Time frame</li>
                            </ul>
                            <select
                              className="form-select"
                              name="experienceType"
                              onChange={handleResourceType}
                              value={experienceType}
                            >
                              <option value=""> Select Experience </option>
                              {getAllExperienceResourcePage &&
                                getAllExperienceResourcePage.map((val) => {
                                  return (
                                    <>
                                      <option value={val.id}> {val.name} </option>
                                    </>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      )}

                      {resourceType === "7" && (
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label>
                              {" "}
                              <strong>Type of Facility</strong>
                            </label>
                            <select
                              className="form-select"
                              name="facilityType"
                              onChange={handleResourceType}
                              value={facilityType}
                            >
                              <option value=""> Select Facility </option>
                              {getAllFacilityResourcePage &&
                                getAllFacilityResourcePage.map((val) => {
                                  return (
                                    <>
                                      <option value={val.id}> {val.name} </option>
                                    </>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      )}
                      {resourceType === "6" && (
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label>
                              {" "}
                              <strong>Type of Funding</strong>
                            </label>
                            <select
                              className="form-select"
                              name="fundingType"
                              onChange={handleResourceType}
                              value={fundingType}
                            >
                              <option value=""> Select Funding </option>
                              {getAllFundingResourcePage &&
                                getAllFundingResourcePage.map((val) => {
                                  return (
                                    <>
                                      <option value={val.id}> {val.name} </option>
                                    </>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      )}

                      {resourceType === "1" && (
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label>
                              {" "}
                              <strong> Type of Organization </strong>
                            </label>
                            <select
                              className="form-select"
                              name="organizationType"
                              onChange={handleResourceType}
                              value={organizationType}
                            >
                              <option value=""> Select Organization </option>
                              {getAllOrganizationResourcePage &&
                                getAllOrganizationResourcePage.map((val) => {
                                  return (
                                    <>
                                      <option value={val.id}> {val.name} </option>
                                    </>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      )}

                      {resourceType === "4" && (
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label>
                              {" "}
                              <strong> Type of Support </strong>
                            </label>
                            <select
                              className="form-select"
                              name="studentSupportType"
                              onChange={handleResourceType}
                              value={studentSupportType}
                            >
                              <option value=""> Select Support </option>
                              {getAllStudentSupportResourcePage &&
                                getAllStudentSupportResourcePage.map((val) => {
                                  return (
                                    <>
                                      <option value={val.id}> {val.name} </option>
                                    </>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      )}

                      {resourceType === "5" && (
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label>
                              {" "}
                              <strong> Type of Support </strong>
                            </label>
                            <select
                              className="form-select"
                              name="teacherParentSupportType"
                              onChange={handleResourceType}
                              value={teacherParentSupportType}
                            >
                              <option value=""> Select Support </option>
                              {getTeacherParentSupportResourcePage &&
                                getTeacherParentSupportResourcePage.map((val) => {
                                  return (
                                    <>
                                      <option value={val.id}> {val.name} </option>
                                    </>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      )}

                      {resourceType === "2" && (
                        <div className="col-lg-4">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label>
                              {" "}
                              <strong> Instructional Style </strong>
                            </label>
                            <select
                              className="form-select"
                              name="teacherParentSupportType"
                              onChange={handleInstructionalStyle}
                              value={instructionalStyleType}
                            >
                              <option value="">Select Instructional Style</option>
                              {getInstructionStyleResourcePage &&
                                getInstructionStyleResourcePage.map((val) => {
                                  return (
                                    <>
                                      <option value={val.id}> {val.name} </option>
                                    </>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      )}

                      {resourceType === "2" && (
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label>
                              {" "}
                              <strong> Time Frame</strong>
                            </label>
                            <select
                              className="form-select"
                              name="timeFrameTypeCariculam"
                              onChange={handleResourceType}
                              value={timeFrameTypeCariculam}
                            >
                              <option value=""> Select TimeFrame </option>
                              <option value="flexible"> Flexible </option>
                              <option value="less than a semester"> Less than a semester </option>
                              <option value="semester"> Semester </option>
                              <option value="school year"> School year </option>
                            </select>
                          </div>
                        </div>
                      )}

                      {resourceType === "5" && (
                        <div className="col-lg-4">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label>
                              {" "}
                              <strong> Customer Type</strong>
                            </label>
                            <select
                              className="form-select"
                              name="timeFrameTypeCariculam"
                              onChange={handleCustomerType}
                              value={customerType}
                            >
                              <option value=""> Select Customer Type </option>
                              <option value="teacher">Teacher </option>
                              <option value="parent">Parent </option>
                              <option value="other"> Other </option>
                            </select>
                          </div>
                        </div>
                      )}

                      {/* {resourceType === "2" ? ( */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          <div className="form-control-wrap" ref={newRef}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdate ? new Date(selectedfromdate) : null}
                              className="form-control date-picker"
                              onChange={HandleFromDate}
                              open={datepickershow}
                              onFocus={() => setDatePickerShow(!datepickershow)}
                            />
                            {/* <input type="date"  placeholder="enter" name ="formdate" className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                          </div>
                          <span className="error">{formDateError}</span>
                        </div>
                      </div>
                      {/* ) : (
                        ""
                      )} */}

                      {/* {resourceType === "2" ? ( */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <div className="form-control-wrap" ref={newRef1}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon1()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selectsEnd
                              selected={selectedtodate ? new Date(selectedtodate) : null}
                              className="form-control date-picker"
                              onChange={HandleToDate}
                              open={datepickershow1}
                              onFocus={() => setDatePickerShow1(!datepickershow1)}
                            />
                            {/* <input type="date"  name ="todate" className="form-control" value={selectedtodate} onChange={HandleToDate}></input> */}
                          </div>
                          <span className="error">{toDateError}</span>
                        </div>
                      </div>
                      {/* ) : (
                        ""
                      )} */}

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Learning Pillars</strong>
                          </label>
                          <div className="form-control-wrap paymetsession">
                            <i
                              className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                              onClick={displaySelectFilter}
                            ></i>
                            <Select
                              className="form-select react-select-container"
                              classNamePrefix="react-select"
                              value={learningPillarIds && learningPillarIds.length > 0 ? learningPillarIds : null}
                              options={learningPillarData}
                              isMulti
                              selectMultiple={true}
                              onChange={handleLearningPillar}
                              // onFocus={() => setShowArrow(!showArrow)}
                              // open={!showArrow}
                              // closeMenuOnSelect
                              openMenuOnFocus={displayvalueSelectFilter}
                              ref={selectRefFilter}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Search by Resource Title/Keyword</strong>
                          </label>
                          <Input
                            name="name"
                            placeholder="Resource Title/keyword"
                            type="text"
                            value={search}
                            onChange={handleSearchTitle}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Search by ISBN Number</strong>
                          </label>
                          <Input
                            name="name"
                            placeholder="Search by ISBN Number"
                            type="number"
                            value={searchByISBN}
                            onChange={handleISBNSearch}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Resource Status</strong>
                          </label>
                          <select
                            className="form-select"
                            name="resourceType"
                            value={resourcestate}
                            onChange={handlestate}
                          >
                            <option value=""> All </option>
                            <option value="publish"> Publish </option>
                            <option value="unpublish"> Unpublish</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Resource Deleted</strong>
                          </label>
                          <select
                            className="form-select"
                            name="resourceType"
                            value={resourcedeletestate}
                            onChange={handledeletestate}
                          >
                            <option value=""> All </option>
                            <option value="yes"> Yes </option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Resource Reviewed</strong>
                          </label>
                          <select value={reviewdresource} className="form-select" onChange={handleReviewResource}>
                            <option value="">All</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Title Available:Yes/No</strong>
                          </label>
                          <select value={titleAv} className="form-select" onChange={handleTitleAv}>
                            <option value="">All</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Description Available:Yes/No</strong>
                          </label>
                          <select value={descriptionAv} className="form-select" onChange={handleDescriptionAv}>
                            <option value="">All</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Thumbnail Images Available:Yes/No</strong>
                          </label>
                          <select value={imageAv} className="form-select" onChange={handleImageAv}>
                            <option value="">All</option>
                            <option value="yes"> Yes</option>
                            <option value="no"> No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>URL Available:Yes/No</strong>
                          </label>
                          <select value={urlAv} className="form-select" onChange={handleUrlAv}>
                            <option value="">All</option>
                            <option value="yes"> Yes</option>
                            <option value="no"> No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Show on Home:Yes/No</strong>
                          </label>
                          <select value={showonHome} className="form-select" onChange={handleShowonHome}>
                            <option value="">All</option>
                            <option value="yes"> Yes</option>
                            <option value="no"> No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Affiliate Resource:Yes/No</strong>
                          </label>
                          <select value={affiliateResource} className="form-select" onChange={handleAffiliate}>
                            <option value="">All</option>
                            <option value="1"> Yes</option>
                            <option value="0"> No</option>
                          </select>
                        </div>
                      </div>

                      <div className="row pb-2">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="outerTypeBox">
                            <button className="btn btn-md btn-primary mt-2" type="submit" onClick={handleSearch}>
                              {" "}
                              Search{" "}
                            </button>{" "}
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="resourceListTableDataarrow" style={{ position: "relative" }}>
                  <div className="outerBox subscriptionplanCustome mt-4" ref={tableRef}>
                    <button
                      className="btn btn-primary fix1"
                      onClick={() => {
                        handleHorizantalScroll(tableRef.current, 25, 100, -10);
                      }}
                      //disabled={arrowDisable}
                    >
                      <i className="fa fa-arrow-left"></i>
                    </button>
                    <button
                      className="btn  btn-primary fix2"
                      onClick={() => {
                        handleHorizantalScroll(tableRef.current, 25, 100, 10);
                      }}
                      //disabled={arrowDisableRight}
                    >
                      <i className="fa fa-arrow-right"></i>
                    </button>

                    {loader ? (
                      <div className="overflowloader" id="preload">
                        <div className="circle-square">
                          <div className="red" style={{ background: "#AAC0CE" }}></div>
                          <div className="blue" style={{ background: "#265472" }}></div>
                          <div className="green" style={{ background: "#F19357" }}></div>
                          <div className="yellow" style={{ background: "#F0B285" }}></div>
                        </div>
                      </div>
                    ) : getAllResource.length > 0 ? (
                      <ReactDataTable
                        data={getAllResource}
                        columns={visibleColumns}
                        className="nk-tb-list"
                        searchable={false}
                        setPageCount={setPageCount}
                      />
                    ) : (
                      <p>No Data Found</p>
                    )}

                    {getAllResource && getAllResource?.length > 0 ? (
                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={count}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                          forcePage={currentPage - 1}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            </div>
          </Card>
        )}
      </Content>
    </React.Fragment>
  );
};
export default ListResource;

import React, { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import { Link } from "react-router-dom";
import { URLS } from "../../constants/urls";
import { makePostRequest } from "../../service/api";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [erroremail, setEmailError] = useState("");
  let Emailvalidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleEmailSend = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handleResetEmail = () => {
    if (email === "") {
      setEmailError("Please enter your email id.");
    } else if (!Emailvalidation.test(email)) {
      setEmailError("The email address you entered is not valid.");
    } else {
      let data = {
        email: email,
      };
      makePostRequest(URLS.FORGET_PASSWORD, null, data, null).then((res) => {
        if (res.code === 200) {
          toast.success("Reset link has been sent to your email.");
        } else {
          toast.warn("User does not exist.");
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Head title="Forgot-Password" />
      <ToastContainer />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5">Reset Password</BlockTitle>
                <BlockDes>
                  <p>Please enter a valid email address to reset your password.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <form>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <input
                  onChange={handleEmailSend}
                  type="text"
                  className="form-control form-control-lg"
                  id="default-01"
                  placeholder="Enter your email address"
                />
                <span className="error"> {erroremail}</span>
              </div>
              <div className="form-group">
                <a
                  href="javascript:void(0);"
                  color="primary"
                  size="lg"
                  className="btn btn-primary btn-block"
                  onClick={handleResetEmail}
                >
                  Send Reset Link
                </a>
              </div>
            </form>
            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Return to Login</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;

import React, { useState, useMemo } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import {
  Icon,
} from "../../../components/Component";
import { Card } from "reactstrap";
import { Label, Input } from "reactstrap";
import { makeGetRequest } from "../../../service/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makePostRequest } from "../../../service/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function ManageState() {
  const [selectedstate, setSelectedState] = useState({});
  const [name, setName] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [errorname, setErrorName] = useState("");
  const [rolesData, setRolesData] = useState([]);
  const [resetname, setResetName] = useState("");
  const [expressionTextError, setErrorExpressionText] = useState("");
  const [loader, setLoader] = useState(false);
  const [switchState, setSwitchState] = useState("");
  let payloadarray = [];
  let tempData = [];
  const [getAllStates, setGetAllStates] = useState([]);
  const [payloadarraypass, setPayloadarraypass] = useState([]);
  const column = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "id", 
        enableEditing: false,
      },
      {
        header: "Funding State",
        accessorKey: "name", 
      },
      {
        header: "Status",
        accessorKey: "status",
        Cell: ({ cell }) =>
          cell.getValue() == "enable" ? (
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" checked />
              <label className="custom-control-label"></label>
            </div>
          ) : (
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" />
              <label className="custom-control-label"></label>
            </div>
          ),
      },
    ],
    []
  );

  const columns = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "50px",
        style: { minWidth: "50px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2"> {index + 1} </div>
          </>
        ),
      },
      {
        name: "Name",
        selector: (row) => row.name,
        grow: 4,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            {/* <UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(row.name)} ></UserAvatar> */}
            <div className="user-info">
              <span className="tb-lead">{row.name} </span>
            </div>
          </div>
        ),
      },

      {
        name: "Status",
        selector: (row) => row.status,
        cell: (row, index) => (
          <>
            {row.status == "enable" && (
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={row.id}
                  onClick={() => handleswitch(row.id, "disable", index)}
                  checked
                />
                <label className="custom-control-label" htmlFor={row.id}></label>
              </div>
            )}
            {row.status == "disable" && (
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={row.id}
                  onClick={() => handleswitch(row.id, "enable", index)}
                />
                <label className="custom-control-label" htmlFor={row.id}></label>
              </div>
            )}
          </>
        ),
      },

      {
        name: "Action",
        selector: (row) => row.status,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <button
              className="dropdown-item"
              onClick={() => {
                handleEditButton(row);
              }}
            >
              <Icon name="edit"></Icon>
            </button>
          </div>
        ),
      },
    ];
  }, [rolesData]);
  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    let data = {
      id: values.id,
      name: values.name.replace(/^\s+|\s+$|\s+(?=\s)/g, ""),
    };
    makePostRequest(URLS.UPDATE_STATES, null, data, null).then((res) => {
      if (res.code == 200) {
        setName("");
        setSelectedState({ ...selectedstate, name: "" });
        toast.success(res.message);
        setLoader(true);
        GetAllStates();
        setIsUpdate(false);
        setSelectedState({});
      } else if (res.code == 400) {
        setLoader(false);
        toast.error(res.message);
      }
    });

    exitEditingMode();
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    tempData = Array.from(rolesData);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setGetAllStates(tempData);
    tempData.length > 0 &&
      tempData.map((val, index) => {
        payloadarray.push({ sort: tempData.length - index, id: val.id });
      });
    setPayloadarraypass(payloadarray);
  };

  const handleswitch = (id, isSelected, index) => {
    setLoader(true);
    let data = {
      id: id,
      status: isSelected,
    };
    switchState ? setSwitchState("enable") : setSwitchState("disable");
    const newArray = Array.from(rolesData);
    newArray[index].status = isSelected === "enable" ? "disable" : "enable";
    setRolesData(newArray);

    makePostRequest(URLS.UPDATE_STATES, null, data, null).then((res) => {
      if (res.code === 200) {
        setLoader(false);
        toast.success("Status updated successfully.");
        GetAllStates();
        setIsUpdate(false);
      } else {
        toast.warn("Status disable successfully.");
      }
    });
  };

  const handleName = (e) => {
    setName(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
    setErrorName("");
    setErrorExpressionText("");
    let obj = { ...selectedstate };
    let str = e.target.value;
    obj.name = str;
    setSelectedState({ ...obj });
  };

  let char = /^[a-zA-Z\s]+$/;
  let num = /^[0-9]+$/;
  const SortFacility = () => {
    let data = payloadarraypass ? payloadarraypass : [];
    makePostRequest(URLS.SORTSTATES, null, data, null).then((res) => {
      if (res.code == 200) {
        toast.success(res.message);
        GetAllStates();
      } else if (res.code == 400) {
        setLoader(false);
        toast.error(res.message);
      }
    });
  };

  useEffect(() => {
    if (payloadarraypass.length > 0) {
      SortFacility();
    }
  }, [payloadarraypass]);

  const Addstatehandler = () => {
    if (name == "") {
      setErrorName("Please enter states name.");
    }
    else if (!name.match(char)) {
      setErrorExpressionText("Please enter character only.");
    }
    else {
      addNewState();
      setLoader(true);
    }
  };
  const Updatestatehandler = () => {
    if (selectedstate.name.replace(/^\s+|\s+$|\s+(?=\s)/g, "") == "") {
      setErrorName("Please enter states name.");
    }
    else if (!selectedstate.name.match(char)) {
      setErrorExpressionText("Please enter character only.");
    }
    else if (isUpdate) {
      HandleUpdateState();
    }
  };

  const addNewState = () => {
    let data = {
      name: name,
      status: "enable",
    };
    makePostRequest(URLS.ADD_STATES, null, data, null).then((res) => {
      if (res.code == 201) {
        let boj = { name: "" };
        setSelectedState(boj);
        toast.success(res.message);
        GetAllStates();
        setIsUpdate(false);
      } else if (res.code == 400) {
        setLoader(false);
        toast.error(res.message);
      }
    });
  };
  useEffect(() => {
    GetAllStates();
  }, []);

  const GetAllStates = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_STATES, {}, null)
      .then((res) => {
        if (res.code == 200) {
          setRolesData(res.data);
          setLoader(false);
        }
      })
      .catch((error) => {
      });
  };

  const handleEditStateButton = (val) => {
    setErrorName("");
    setIsUpdate(true);
    let obj = { id: val.id, name: val.name };
    setSelectedState(obj);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  const HandleUpdateState = () => {
    let data = {
      id: selectedstate.id,
      name: selectedstate.name.replace(/^\s+|\s+$|\s+(?=\s)/g, ""),
    };
    makePostRequest(URLS.UPDATE_STATES, null, data, null).then((res) => {
      if (res.code == 200) {
        setName("");
        //  setSort("");
        setSelectedState({ ...selectedstate, name: "" });
        toast.success(res.message);
        setLoader(true);
        GetAllStates();
        setIsUpdate(false);
        setSelectedState({});
      } else if (res.code == 400) {
        setLoader(false);
        toast.error(res.message);
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="State List"></Head>
      <Content>
        <ToastContainer />
        <h3 className="nk-block-title page-title mb5 pb-4">Manage Funding States</h3>
        {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "250px" }}>
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : (
          <Card className="card-bordered">
            <div className="card-inner-lg">
              <BlockHeadContent>
                <BlockHead size="lg">
                  <div className="row mb-4">
                    <div className="col-lg-4">
                      {isUpdate === false ? (
                        <Label className="form-label">Add Funding State Name</Label>
                      ) : (
                        <Label className="form-label">Edit Funding State Name</Label>
                      )}

                      <div className="form-control-wrap">
                        <Input
                          name="name"
                          placeholder="Funding State Name"
                          type="text"
                          value={selectedstate.name}
                          onChange={handleName}
                        />
                        <span className="error"> {errorname} </span>
                        <span className="error"> {expressionTextError} </span>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <Label className="form-label">&nbsp;</Label>
                      {isUpdate === false ? (
                        <ul className="">
                          <li>
                            <button className="btn btn-primary btn-md" type="submit" onClick={Addstatehandler}>
                              Add{" "}
                            </button>
                          </li>
                        </ul>
                      ) : (
                        <ul className="">
                          <li>
                            <button className="btn btn-primary btn-md" type="submit" onClick={Updatestatehandler}>
                              Update{" "}
                            </button>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>

                  {isUpdate === false && (
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <table className="table borderd">
                        <thead>
                          <tr className="p-3 bg-white border border-light round-lg mb-3">
                            <th />
                            <th>#</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <Droppable droppableId="droppable-1">
                          {(provider) => (
                            <tbody className="text-capitalize" ref={provider.innerRef} {...provider.droppableProps}>
                              {rolesData?.map((user, index) => (
                                <Draggable
                                  key={user.name}
                                  draggableId={user.name}
                                  index={index}
                                >
                                  {(provider) => (
                                    <tr
                                      {...provider.draggableProps}
                                      ref={provider.innerRef}
                                      className="p-3 bg-white border border-light round-lg mb-3"
                                    >
                                      <td {...provider.dragHandleProps}>
                                        <div className="mt-2 mb-2">
                                          {" "}
                                          <FontAwesomeIcon icon={faGripVertical} />{" "}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="mt-2 mb-2">{index + 1}</div>
                                      </td>
                                      <td>
                                        <div className="user-card mt-2 mb-2">
                                          <div className="user-info">
                                            <span className="userName">{user.name} </span>
                                          </div>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="mt-2 mb-2">
                                          {user.status == "enable" && (
                                            <div className="custom-control custom-switch">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={user.id}
                                                onClick={() => handleswitch(user.id, "disable", index)}
                                                checked
                                              />
                                              <label className="custom-control-label" htmlFor={user.id}></label>
                                            </div>
                                          )}
                                          {user.status == "disable" && (
                                            <div className="custom-control custom-switch">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={user.id}
                                                onClick={() => handleswitch(user.id, "enable", index)}
                                              />
                                              <label className="custom-control-label" htmlFor={user.id}></label>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="user-card mt-2 mb-2">
                                          <button
                                            className="dropdown-item"
                                            onClick={() => {
                                              handleEditStateButton(user);
                                            }}
                                          >
                                            <Icon name="edit"></Icon>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                              {provider.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                    </DragDropContext>
                  )}
                </BlockHead>
              </BlockHeadContent>
            </div>
          </Card>
        )}
      </Content>
    </React.Fragment>
  );
}

export default ManageState;

import { useState, useEffect } from "react";

function ChildCard(props) {
  console.log(props,"props")
  let data = props.title;
  let selectedChildIds = props.selectedChildIds;

  const [active, setActive] = useState(false);
  const handleToggle = (id) => {
    setActive(!active);
    props.handleChildIds(id, !active);
  };

  return (
    <div
      onClick={() => {
        handleToggle(data.id);
      }}
      className={`save-jackey-div1 ${
        active || (selectedChildIds.includes(data.id) && setActive(!active))
          ? "active"
          : ""
      }`}
    >
      <div
        className={`save-jackey-div1 inner-resource ${
          active || (selectedChildIds.includes(data.id) && setActive(!active))
            ? "active"
            : ""
        }`}
      ></div>
      <h3>
        {data.firstName} {data.lastName}
      </h3>
    </div>
  );
}

export default ChildCard;
